<template>
  <div>
    <div class="container pb-55-mobile container500">
      <div class="mb-2-desktop"></div>
      <h1 class="title my-1">Substituir cartão atual</h1>

      <div v-show="creatingSubscription" class="bg-cover flex-center">
        <div class="desktop12 tablet12 mobile12 flex-center flex-column">
          <div class="loading-spinner"></div>
          <p class="text-desc mt-1 text-white text-center">
            Processando novo cartão.
            <br />Por favor, aguarde.
          </p>
        </div>
      </div>

      <div class="input-holder labeled login first desktop12 tablet12 mobile12">
        <p class="text-desc text-brown text-bold">Nome no cartão novo *</p>
        <img src="../assets/images/icon-card.png" />
        <input v-model="creditCard.name" type="text" />
      </div>
      <div class="input-holder labeled login first desktop12 tablet12 mobile12">
        <p class="text-desc text-brown text-bold">Número do cartão novo *</p>
        <img src="../assets/images/icon-card.png" />
        <the-mask
          @input="checkCardFlag()"
          v-model="creditCard.number"
          :mask="['#### #### #### ####']"
          :masked="false"
        />
      </div>
      <div class="flex-between">
        <div class="input-holder labeled login first desktop5 tablet5 mobile5">
          <p class="text-desc text-brown text-bold">vencimento *</p>
          <img src="../assets/images/icon-calendar.png" />
          <the-mask v-model="creditCard.month" placeholder="Mês" :mask="['##']" :masked="false" />
        </div>
        <div class="input-holder labeled login first desktop5 tablet5 mobile5">
          <p class="text-desc text-brown text-bold"></p>
          <img src="../assets/images/icon-calendar.png" />
          <the-mask v-model="creditCard.year" placeholder="Ano" :mask="['##']" :masked="false" />
        </div>
      </div>
      <div class="input-holder labeled login first desktop12 tablet12 mobile12">
        <p class="text-desc text-brown text-bold">Código de segurança *</p>
        <img src="../assets/images/icon-lock.png" />
        <input v-model="creditCard.securityCode" type="number" placeholder="Ex: 123" />
      </div>

      <transition enter-active-class="animated fadeIn">
        <div v-show="creditCard.name && creditCard.number" class="creditcard my-2">
          <img class="chip" src="../assets/images/icon-cpu.png" alt />
          <p class="subtitle number text-white">{{creditCard.number}}</p>
          <p style="text-transform:uppercase;" class="subtitle name text-white">{{creditCard.name}}</p>
          <div class="dates flex-start">
            <p style="filter:blur(3px);" class="text-desc text-white">Emitido: 01/01/20</p>
            <p class="text-desc ml-1 text-white">
              Validade:
              <b>{{` ${this.creditCard.month}/${this.creditCard.year}`}}</b>
            </p>
          </div>
          <img v-show="creditCardFlagImage" :src="creditCardFlagImage" class="flag" />
          <p class="text-desc security-code text-white">{{creditCard.securityCode}}</p>
        </div>
      </transition>

      <transition enter-active-class="animated fadeIn">
        <p
          v-show="warningMessage != ''"
          class="text-desc text-blue text-center mt-2"
        >{{ warningMessage }}</p>
      </transition>

      <button @click="updateCreditCard()" class="default bg-brown mt-2 mb-2">
        <span v-show="!loading">Trocar cartão</span>
        <div v-show="loading" class="loading-spinner"></div>
      </button>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import validators from "../util/validators";
import authentication from "../util/authentication";
export default {
  name: "CreditCard",
  data() {
    return {
      creditCard: {
        number: "",
        name: "",
        month: "",
        year: "",
        securityCode: "",
        flag: ""
      },
      creditCardFlagImage: "",
      warningMessage: "",
      loading: false,
      creatingSubscription: false,
      userSubscription: Object
    };
  },
  created() {
    http
      .get("user/subscription", authentication.getHeaders())
      .then(response => {
        this.userSubscription = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    sendCreditcard() {
      var vm = this;
      var cc = this.creditCard;
      vm.creatingSubscription = true;

      consulta.getPaymentToken(
        {
          brand: cc.flag, // bandeira do cartão
          number: cc.number, // número do cartão
          cvv: cc.securityCode, // código de segurança
          expiration_month: cc.month, // mês de vencimento
          expiration_year: cc.year // ano de vencimento
        },
        function(error, response) {
          if (error) {
            console.log(error);
            vm.creatingSubscription = false;
            vm.loading = false;
          } else {
            var payment_token = response.data.payment_token;
            http
              .put(
                `Subscription/${vm.userSubscription.id}?paymentToken=${payment_token}`,
                {},
                authentication.getHeaders()
              )
              .then(res => {
                this.$swal.fire({
                  type: "success",
                  title: "Cartão atualizado"
                });
              })
              .catch(err => {
                console.log(err);
                vm.$swal.fire({
                  title: "Algo deu errado",
                  text: `Por favor, verifique todos os dados e tente novamente.`,
                  type: "error",
                  position: "center",
                  allowEnterKey: false,
                  customClass: {
                    title: "title-class",
                    closeButton: "close-button-class",
                    content: "text-desc-popup"
                  }
                });
              })
              .finally(() => {
                vm.loading = false;
              });
            vm.creatingSubscription = false;
          }
        }
      );
    },
    updateCreditCard() {
      if (this.creditCard.name == "") {
        this.warningMessage =
          "Por favor, informe o nome igual está escrito no cartão";
        return;
      }
      if (this.creditCard.month == "") {
        this.warningMessage = "Por favor, informe o mês de validade do cartão";
        return;
      }
      if (this.creditCard.year == "") {
        this.warningMessage = "Por favor, informe o ano de validade do cartão";
        return;
      }
      if (this.creditCard.securityCode == "") {
        this.warningMessage =
          "Por favor, informe o código de segurança do cartão";
        return;
      }
      this.warningMessage = "";
      this.loading = true;

      this.sendCreditcard();
    },
    checkCardFlag() {
      this.creditCard.flag = validators.cardFlag(this.creditCard.number);
      if (this.creditCard.flag == "mastercard") {
        this.creditCardFlagImage = require("../assets/images/mastercard.png");
      } else if (this.creditCard.flag == "visa") {
        this.creditCardFlagImage = require("../assets/images/visa.png");
      } else if (this.creditCard.flag == "amex") {
        this.creditCardFlagImage = require("../assets/images/amex.jpg");
      } else {
        this.creditCardFlagImage = "";
      }
    }
  }
};
</script>