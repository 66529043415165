<template>
	<div>
		<Header />

		<transition enter-active-class="page-anim2">
			<Profile v-show="tab == 'profile'"></Profile>
		</transition>
		<transition enter-active-class="page-anim2">
			<Plan v-show="tab == 'plan'"></Plan>
		</transition>
		<transition enter-active-class="page-anim2">
			<CreditCard v-show="tab == 'credit'"></CreditCard>
		</transition>
		<transition enter-active-class="page-anim2">
			<Password v-show="tab == 'password'"></Password>
		</transition>

		<div class="profile-menu-holder">
			<div
				class="profile-menu-item"
				:class="{ active: tab == 'profile' }"
				@click="tab = 'profile'"
			>
				<img src="../assets/images/icon-user.png" class="shorter" alt />
				<p class="text-blue">{{ t("Perfil") }}</p>
			</div>
			<div
				class="profile-menu-item"
				:class="{ active: tab == 'plan' }"
				@click="tab = 'plan'"
			>
				<img src="../assets/images/icon-shop.png" class="shorter" alt />
				<p class="text-blue">{{ t("Plano") }}</p>
			</div>
			<!-- <div class="profile-menu-item" :class="{ active: tab == 'credit' }" @click="tab = 'credit'">
        <img src="../assets/images/icon-card.png" alt />
        <p class="text-blue">Cartão</p>
      </div>-->
			<div
				class="profile-menu-item"
				:class="{ active: tab == 'password' }"
				@click="tab = 'password'"
			>
				<img src="../assets/images/icon-lock.png" class="shorter" alt />
				<p class="text-blue">{{ t("Senha") }}</p>
			</div>
		</div>
	</div>
</template>
<script>
	import Header from "../components/Header";
	import Profile from "../components/Profile";
	import CreditCard from "../components/CreditCard";
	import Password from "../components/Password";
	import Plan from "../components/Plan";

	export default {
		name: "MeusDados",
		components: {
			Header,
			Profile,
			CreditCard,
			Password,
			Plan,
		},
		data() {
			return {
				tab: "profile",
			};
		},
		created() {
			if (this.$route.query.tab === "plan") {
				this.tab = "plan";
			}
		},
	};
</script>
