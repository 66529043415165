<template>
	<div>
		<Header :showBackButton="true" />

		<section class="page-content page-anim2">
			<div class="container pb-2">
				<h1 class="title text-blue my-1 section-title">
					{{ t("Próximos lançamentos") }}
				</h1>

				<div class="cards-home-holder small-children mt-2">
					<div
						v-for="template in templates"
						:key="template.id"
						class="category-card"
						style="cursor: default !important;"
					>
						<div class="img-holder">
							<img class="bg-img" :src="template.coverImage" />
						</div>
						<p class="text-desc card-desc text-brown">{{ template.title }}</p>
					</div>
				</div>

				<div v-show="loading" class="flex-center mt-2">
					<div class="loading-spinner blue"></div>
				</div>

				<div
					v-show="templates.length == 0 && !loading"
					class="flex-center mt-2"
				>
					<p class="text-desc text-center mt-3">
						{{ t("Nenhum template encontrado") }}
					</p>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import http from "@/http";
	import Header from "../components/Header";
	import authentication from "../util/authentication";
	export default {
		name: "TemplateProximos",
		components: {
			Header,
		},
		data() {
			return {
				loading: true,
				templates: [],
			};
		},
		created() {
			this.loadTemplates();
		},
		methods: {
			loadTemplates() {
				http
					.get("template/nextMonth", authentication.getHeaders())
					.then((response) => {
						this.templates = response.data;
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			goBack() {},
		},
	};
</script>
<style scoped>
	button.default {
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
</style>
