<template>
	<div>
		<Header :showBackButton="true" />

		<section class="page-content page-anim2">
			<div class="container pb-2">
				<h1 class="title text-blue my-1 section-title">
					<span>{{ t("Categoria") }}: </span>{{ template.category.title }}
				</h1>

				<div class="category-card no-inner-shadow mt-2 template-details">
					<transition enter-active-class="animated fadeIn">
						<img
							v-show="template.coverImage"
							class="bg-img no-zoom"
							:src="template.coverImage"
						/>
					</transition>
				</div>
				<transition enter-active-class="animated fadeIn">
					<h1
						v-show="template.title"
						class="title text-brown mt-1-desktop template-details-title"
					>
						{{ template.title }}
					</h1>
				</transition>
				<transition enter-active-class="animated fadeIn">
					<p
						v-show="template.description"
						class="text-desc text-blue mt-1"
						v-html="template.description"
					></p>
				</transition>
				<transition enter-active-class="animated zoomIn">
					<a v-show="template.id" :href="`#/edicao/${template.id}`">
						<button class="default template-details-button">
							{{ t("Usar template") }}
						</button>
					</a>
				</transition>
			</div>
		</section>
	</div>
</template>
<script>
	import http from "@/http";
	import Header from "../components/Header";
	import authentication from "../util/authentication";
	export default {
		name: "TemplateDetalhes",
		components: {
			Header,
		},
		data() {
			return {
				loading: false,
				template: {
					title: "",
					image: "",
					coverImage: "",
					category: {},
					description: "",
				},
			};
		},
		created() {
			this.loading = true;
			http
				.get(`template/${this.$route.params.id}`, authentication.getHeaders())
				.then((response) => {
					this.template = response.data;
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		methods: {
			goBack() {},
		},
	};
</script>
<style scoped>
	.title span {
		position: relative;
		font-family: fontLight;
		font-size: 0.5em;
		color: #ba8368;
		margin-right: 10px;
		top: -5px;
	}

	@media only screen and (max-width: 1139px) {
		h1.title {
			margin-top: 25px !important;
		}
	}
	button.default {
		max-width: 300px;
		margin: 25px auto 0 auto;
	}
</style>
