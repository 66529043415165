<template>
	<div>
		<Header></Header>

		<transition
			enter-active-class="animated fadeIn"
			leave-active-class="animated fadeOut"
		>
			<div v-show="loading" class="bg-cover flex-center">
				<div class="loading-spinner"></div>
			</div>
		</transition>

		<section class="page-content page-anim2">
			<div class="container container500 pb-2">
				<h1 class="title text-blue my-1 section-title">
					{{ t("Minha foto") }}
				</h1>
				<p v-show="canChangePicture" class="text-desc">
					{{ t("Escolha uma foto para ser usada em sua assinatura:") }}
				</p>

				<p
					v-show="(showSignature && !success) || !canChangePicture"
					class="text-desc mt-1 text-left-desktop"
					style="color:#f00;"
				>
					{{
						t("Você só pode alterar a imagem da sua assinatura a cada 30 dias.")
					}}
				</p>

				<button v-show="canChangePicture" class="default options bg-blue">
					<input
						id="fileInput"
						@change="processFile($event)"
						ref="myFiles"
						type="file"
					/>
					<img src="../assets/images/icon-cam-white.png" alt />
					<span>{{ t("Escolher imagem") }}</span>
				</button>

				<p class="text-desc text-center mt-2">
					{{ t("Última troca em") }}:
					<b>{{ user.imageLastModified | moment("DD/MM/YYYY") }}</b>
				</p>

				<transition enter-active-class="animated fadeIn">
					<div
						v-show="showSignature"
						class="signature-preview-holder mt-3 mb-2"
					>
						<img id="signatureImg" src />
						<div class="white-line"></div>
						<div class="text-holder">
							<p class="text-desc">
								<i>{{ user.professionalName }}</i>
							</p>
							<p class="text-desc">
								<i>{{ user.career[user.genre] }}</i>
							</p>
						</div>
						<img class="watermark" src="../assets/images/doit-watermark.jpg" />
					</div>
				</transition>

				<button
					v-if="canChangePicture"
					v-show="canChangePicture"
					@click="save()"
					class="default bg-blue"
				>
					<span>{{ t("Salvar") }}</span>
				</button>
			</div>
		</section>

		<transition leave-active-class="animated fadeOut">
			<ImageCrop
				v-show="selectedImage != ''"
				:imageData="selectedImage"
				:cropSettings="imageCropSettings"
				@photoCropConfirm="photoCropConfirmed"
				@photoCropCancel="photoCropCanceled"
			></ImageCrop>
		</transition>
	</div>
</template>
<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	import Header from "../components/Header";
	import ImageCrop from "../components/ImageCrop";
	export default {
		name: "Assinatura",
		components: {
			Header,
			ImageCrop,
		},
		data() {
			return {
				loading: false,
				showSignature: false,
				selectedImage: "",
				imageCropSettings: {
					aspectRatio: 1,
					shape: "circle",
				},
				user: {
					name: "",
					career: "",
					genre: "",
					imageLastModified: "",
					image: "",
				},
				success: false,
				canChangePicture: false,
			};
		},
		created() {
			http
				.get("user/details", authentication.getHeaders())
				.then((response) => {
					this.user = response.data;
					localStorage.x = JSON.stringify(response.data);
					this.verifySignature();
				})
				.catch((err) => {
					console.log(err);
					if (err.response.status === 401) this.$router.push("login");
				});
		},
		mounted() {},
		methods: {
			verifySignature() {
				if (this.user.image) {
					document.getElementById("signatureImg").src = this.user.image;
					this.showSignature = true;
				}
				let today = this.$moment(new Date());
				let lastUpdatedPicture = this.user.imageLastModified
					? this.$moment(this.user.imageLastModified)
					: this.$moment("1900/01/01");

				if (today.diff(lastUpdatedPicture, "day") > 30) {
					this.canChangePicture = true;
				}
			},
			photoCropConfirmed(dataImage) {
				let objImage = document.getElementById("signatureImg");
				objImage.src = dataImage;

				this.showSignature = true;
				this.selectedImage = "";
			},
			photoCropCanceled() {
				this.selectedImage = "";
			},
			processFile(event) {
				this.loading = true;
				var vm = this; // vue model;
				var reader = new FileReader();
				var img = new Image();

				if (this.$refs.myFiles.files && this.$refs.myFiles.files[0]) {
					reader.readAsDataURL(this.$refs.myFiles.files[0]);
					reader.onload = function(event) {
						if (event.target.readyState == FileReader.DONE) {
							vm.loading = false;
							vm.selectedImage = event.target.result;
						}
					};
				} else {
					vm.loading = false;
				}
			},
			save() {
				let img = document.getElementById("signatureImg")
					? document.getElementById("signatureImg").src
					: "";
				if (img) {
					this.loading = true;
					this.user.image = img;
					http
						.put("user", this.user, authentication.getHeaders())
						.then((response) => {
							let objUser = JSON.parse(localStorage.user);
							objUser.info = response.data;
							localStorage.user = JSON.stringify(objUser);
							this.$swal.fire({
								type: "success",
								title: this.$translate.locale[
									"Assinatura atualizada com sucesso."
								],
							});
							this.canChangePicture = false;
						})
						.catch((err) => {
							console.log(err);
							this.$swal.fire({
								type: "warning",
								title: this.$translate.locale["Algo deu errado"],
								text: this.$translate.locale[
									"Se o problema persistir, por favor, informe a equipe Do It."
								],
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			},
		},
	};
</script>
<style scoped>
	@media only screen and (max-width: 1139px) {
		h1.title {
			margin-top: 25px !important;
		}
	}
	button.default {
		margin: 25px auto 0 auto;
	}

	button.options {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
	}

	button.options img {
		position: relative;
		max-width: 22px;
		max-height: 28px;
		width: initial;
		display: block;
		margin-right: 20px;
	}

	#fileInput {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
		opacity: 0;
		outline: none;
	}
</style>
