export default {
  br: {
    "Ver todas as novidades": "Ver todas as novidades",
    Novidades: "Novidades",
    "Algo deu errado ao tentar cancelar seu plano. Se o problema persistir, por favor, entre em contato com o suporte e informe sobre este erro.":
      "Algo deu errado ao tentar cancelar seu plano. Se o problema persistir, por favor, entre em contato com o suporte e informe sobre este erro.",
    "a conversão exata para a sua moeda será feita pelo seu banco no momento do pagamento.":
      "a conversão exata para a sua moeda será feita pelo seu banco no momento do pagamento.",
    "Você poderá cancelar seu plano a qualquer momento.":
      "Você poderá cancelar seu plano a qualquer momento.",
    "Dados de Pagamento": "Dados de Pagamento",
    "Estado ou Província": "Estado ou Província",
    Cancelada: "Cancelada",
    "Já sou cadastrado": "Já sou cadastrado",
    "Idiomas:": "Idiomas:",
    Senha: "Senha",
    Entrar: "Entrar",
    "Não sou cadastrado": "Não sou cadastrado",
    Cadastrar: "Cadastrar",
    "Esqueceu sua senha?": "Esqueceu sua senha?",
    "Por favor, verifique se o e-mail digitado está correto.":
      "Por favor, verifique se o e-mail digitado está correto.",
    "Por favor, informe sua senha": "Por favor, informe sua senha",
    "Usuário e/ou senha incorreto(s)": "Usuário e/ou senha incorreto(s)",
    "Algo deu errado. Por favor, tente novamente em alguns instantes.":
      "Algo deu errado. Por favor, tente novamente em alguns instantes.",
    Cadastro: "Cadastro",
    "Código País": "Código País",
    Passo: "Passo",
    "Processando pagamento": "Processando pagamento",
    "Por favor, aguarde": "Por favor, aguarde",
    "Dados pessoais": "Dados pessoais",
    "Nome completo": "Nome completo",
    "Ex:": "Ex:",
    "Nome profissional": "Nome profissional",
    CPF: "CPF",
    "Data de nascimento": "Data de nascimento",
    Telefone: "Telefone",
    CEP: "CEP",
    UF: "UF",
    Cidade: "Cidade",
    Bairro: "Bairro",
    Endereço: "Endereço",
    Número: "Número",
    Gênero: "Gênero",
    "Confirmar Senha": "Confirmar Senha",
    "Selecione uma opção": "Selecione uma opção",
    Feminino: "Feminino",
    Masculino: "Masculino",
    "Nível de Carreira": "Nível de Carreira",
    "Ao continuar você confirma que": "Ao continuar você confirma que",
    "leu e está de acordo com os nossos": "leu e está de acordo com os nossos",
    "termos de uso": "termos de uso",
    Avançar: "Avançar",
    Planos: "Planos",
    mês: "mês",
    "Assinatura mensal recorrente": "Assinatura mensal recorrente",
    "Cupom de desconto": "Cupom de desconto",
    "Validar Cupom": "Validar Cupom",
    "Escolha a forma de pagamento": "Escolha a forma de pagamento",
    "Cartão de Crédito": "Cartão de Crédito",
    Boleto: "Boleto",
    "Resumo do Pedido": "Resumo do Pedido",
    Nome: "Nome",
    Plano: "Plano",
    "Descrição do plano": "Descrição do plano",
    "Método de Pagamento": "Método de Pagamento",
    "Dados do cartão": "Dados do cartão",
    "Número da residência": "Número da residência",
    "Nome impresso no cartão": "Nome impresso no cartão",
    "Número do cartão": "Número do cartão",
    "Bandeira do cartão": "Bandeira do cartão",
    Vencimento: "Vencimento",
    Mês: "Mês",
    Ano: "Ano",
    "Código de segurança": "Código de segurança",
    Validade: "Validade",
    "Por favor, informe um cep válido": "Por favor, informe um cep válido",
    "Por favor, informe o seu nome inteiro":
      "Por favor, informe o seu nome inteiro",
    "Por favor, informe o seu nome profissional":
      "Por favor, informe o seu nome profissional",
    "Por favor, informe um e-mail válido":
      "Por favor, informe um e-mail válido",
    "Por favor, informe o seu cpf corretamente":
      "Por favor, informe o seu cpf corretamente",
    "Por favor, informe sua data de nascimento corretamente":
      "Por favor, informe sua data de nascimento corretamente",
    "Por favor, informe um número de telefone ou celular válido":
      "Por favor, informe um número de telefone ou celular válido",
    "Por favor, informe o seu cep": "Por favor, informe o seu cep",
    "Por favor, informe um UF válido": "Por favor, informe um UF válido",
    "Por favor, informe a cidade": "Por favor, informe a cidade",
    "Por favor, informe o bairro": "Por favor, informe o bairro",
    "Por favor, informe o seu endereço": "Por favor, informe o seu endereço",
    "Por favor, informe o seu número": "Por favor, informe o seu número",
    "Por favor, informe o seu gênero": "Por favor, informe o seu gênero",
    "Por favor, informe o seu nível de carreira":
      "Por favor, informe o seu nível de carreira",
    "Por favor, confirme a sua senha": "Por favor, confirme a sua senha",
    "Suas senhas são diferentes. Por favor, digite-as novamente":
      "Suas senhas são diferentes. Por favor, digite-as novamente",
    "Por favor, selecione um plano para continuar":
      "Por favor, selecione um plano para continuar",
    "Por favor, informe o e-mail do dono do cartão":
      "Por favor, informe o e-mail do dono do cartão",
    "Por favor, informe o cpf do dono o cartão":
      "Por favor, informe o cpf do dono o cartão",
    "Por favor, informe a data de nascimento do dono do cartão":
      "Por favor, informe a data de nascimento do dono do cartão",
    "Por favor, informe o número de telefone ou celular do dono do cartão":
      "Por favor, informe o número de telefone ou celular do dono do cartão",
    "Por favor, informe o cep do dono do cartão":
      "Por favor, informe o cep do dono do cartão",
    "Por favor, informe o UF do dono do cartão":
      "Por favor, informe o UF do dono do cartão",
    "Por favor, informe a cidade do dono do cartão":
      "Por favor, informe a cidade do dono do cartão",
    "Por favor, informe o bairro do dono do cartão":
      "Por favor, informe o bairro do dono do cartão",
    "Por favor, informe o endereço do dono do cartão":
      "Por favor, informe o endereço do dono do cartão",
    "Por favor, informe o número do dono do cartão":
      "Por favor, informe o número do dono do cartão",
    "Por favor, informe o número do cartão":
      "Por favor, informe o número do cartão",
    "Por favor, informe a bandeira do cartão":
      "Por favor, informe a bandeira do cartão",
    "Por favor, informe o nome igual está escrito no cartão":
      "Por favor, informe o nome igual está escrito no cartão",
    "Por favor, informe o mês de validade do cartão":
      "Por favor, informe o mês de validade do cartão",
    "Por favor, informe o ano de validade do cartão no formato AAAA":
      "Por favor, informe o ano de validade do cartão no formato AAAA",
    "Por favor, informe o código de segurança do cartão":
      "Por favor, informe o código de segurança do cartão",
    "Cadastro realizado com sucesso": "Cadastro realizado com sucesso",
    "Recebemos seu pedido": "Recebemos seu pedido",
    "Você recebrá o boleto no": "Você recebrá o boleto no",
    "em até 48 horas": "em até 48 horas",
    "Algo deu errado": "Algo deu errado",
    "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente":
      "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente",
    "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente.":
      "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente.",
    "Recebemos sua transação": "Recebemos sua transação",
    "A confirmação do pagamento pode levar até 48 horas":
      "A confirmação do pagamento pode levar até 48 horas",
    "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone, pode manter o seu)":
      "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone, pode manter o seu)",
    Rua: "Rua",
    "DD/MM/AAAA": "DD/MM/AAAA",
    "nome@dominio.com": "nome@dominio.com",
    AAAA: "AAAA",
    "Escolha uma categoria": "Escolha uma categoria",
    "Algo deu errado com o seu pagamento. Por favor, tente novamente indo em: 'menu - meus dados - plano' para liberar as funcionalidades do app":
      "Algo deu errado com o seu pagamento. Por favor, tente novamente indo em: 'menu - meus dados - plano' para liberar as funcionalidades do app",
    Início: "Início",
    "Meus Templates": "Meus Templates",
    "Próximos Lançamentos": "Próximos Lançamentos",
    "Próximos lançamentos": "Próximos lançamentos",
    "Minha Foto": "Minha Foto",
    "Meus Dados": "Meus Dados",
    Sugestões: "Sugestões",
    Sair: "Sair",
    "O que você está buscando?": "O que você está buscando?",
    Buscar: "Buscar",
    "Pagamento Pendente": "Pagamento Pendente",
    "Ops. Nada por aqui.": "Ops. Nada por aqui.",
    Compartilhar: "Compartilhar",
    Mover: "Mover",
    "Criar Pasta": "Criar Pasta",
    Excluir: "Excluir",
    "Renomear Pasta": "Renomear Pasta",
    "Excluir Pasta": "Excluir Pasta",
    "Pasta anterior": "Pasta anterior",
    "Mover template para:": "Mover template para:",
    "Selecione uma pasta": "Selecione uma pasta",
    "Você precisa selecionar uma pasta": "Você precisa selecionar uma pasta",
    "Deletar Template": "Deletar Template",
    "Tem certeza que deseja excluir este template?":
      "Tem certeza que deseja excluir este template?",
    CANCELAR: "CANCELAR",
    Cancelar: "Cancelar",
    SIM: "SIM",
    "Novo nome para a pasta": "Novo nome para a pasta",
    RENOMEAR: "RENOMEAR",
    "Por favor, informe o nome da pasta": "Por favor, informe o nome da pasta",
    "Deletar pasta": "Deletar pasta",
    "Tem certeza que deseja excluir a pasta":
      "Tem certeza que deseja excluir a pasta",
    "e todo o seu conteúdo?": "e todo o seu conteúdo?",
    "Nome da pasta": "Nome da pasta",
    CRIAR: "CRIAR",
    "Nenhum template encontrado": "Nenhum template encontrado",
    "Escolha uma foto para ser usada em sua assinatura:":
      "Escolha uma foto para ser usada em sua assinatura:",
    "Minha foto": "Minha foto",
    "Escolher imagem": "Escolher imagem",
    "Você só pode alterar a imagem da sua assinatura a cada 30 dias.":
      "Você só pode alterar a imagem da sua assinatura a cada 30 dias.",
    Salvar: "Salvar",
    "Assinatura atualizada com sucesso.": "Assinatura atualizada com sucesso.",
    "Se o problema persistir, por favor, informe a equipe Do It.":
      "Se o problema persistir, por favor, informe a equipe Do It.",
    Perfil: "Perfil",
    "Meu perfil": "Meu perfil",
    "Dados atualizados. Obrigado.": "Dados atualizados. Obrigado.",
    Atualizar: "Atualizar",
    "Por favor, informe o seu estado": "Por favor, informe o seu estado",
    "Por favor, informe a sua cidade": "Por favor, informe a sua cidade",
    "Por favor, informe o seu bairro": "Por favor, informe o seu bairro",
    "Detalhes do plano": "Detalhes do plano",
    Assinatura: "Assinatura",
    Pago: "Pago",
    "Aguardando Pagamento": "Aguardando Pagamento",
    "do plano": "do plano",
    Avulsos: "Avulsos",
    "Renovação em": "Renovação em",
    Usuário: "Usuário",
    "Obter mais downloads de templates": "Obter mais downloads de templates",
    "Ative a sua assinatura para ter acesso a todas as funcionalidades do aplicativo.":
      "Ative a sua assinatura para ter acesso a todas as funcionalidades do aplicativo.",
    "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual.":
      "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual.",
    "Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão.":
      "Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão.",
    "Caso você esteja utilizando algum cupom, no cancelamento da assinatura ele também será cancelado, mas você poderá utilizar um novo cupom - caso tenha acesso a algum que esteja vigente na data de escolha do novo plano.":
      "Caso você esteja utilizando algum cupom, no cancelamento da assinatura ele também será cancelado, mas você poderá utilizar um novo cupom - caso tenha acesso a algum que esteja vigente na data de escolha do novo plano.",
    "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail":
      "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail",
    "Seu plano foi cancelado.": "Seu plano foi cancelado.",
    "Cancelar Assinatura": "Cancelar Assinatura",
    "Ativar Assinatura": "Ativar Assinatura",
    "neste mês": "neste mês",
    Ilimitado: "Ilimitado",
    "CANCELAR ASSINATURA": "CANCELAR ASSINATURA",
    "Tem certeza que deseja cancelar a sua assinatura? Esta ação não poderá ser desfeita.":
      "Tem certeza que deseja cancelar a sua assinatura? Esta ação não poderá ser desfeita.",
    NÃO: "NÃO",
    "Alterar senha": "Alterar senha",
    "Senha Atual": "Senha Atual",
    "Nova Senha": "Nova Senha",
    "Confirmar Nova Senha": "Confirmar Nova Senha",
    "Sua senha foi alterada com sucesso.":
      "Sua senha foi alterada com sucesso.",
    "Sua nova senha está diferente da confirmação de senha.":
      "Sua nova senha está diferente da confirmação de senha.",
    "Por favor, confirme a sua senha nova":
      "Por favor, confirme a sua senha nova",
    "Por favor, informe a sua senha nova":
      "Por favor, informe a sua senha nova",
    "Por favor, informe a sua senha atual":
      "Por favor, informe a sua senha atual",
    "Templates Extras": "Templates Extras",
    "Escolha o pacote que melhor lhe atende e não fique sem os templates que você quer!":
      "Escolha o pacote que melhor lhe atende e não fique sem os templates que você quer!",
    "Os download extras de templates adquiridos aqui não possuem prazo de validade. Duram até você usar todos.":
      "Os download extras de templates adquiridos aqui não possuem prazo de validade. Duram até você usar todos.",
    templates: "templates",
    "E-mail": "E-mail",
    "Comprar Pacote": "Comprar Pacote",
    "Seu pagamento está sendo processado. Isso pode levar até 48 horas":
      "Seu pagamento está sendo processado. Isso pode levar até 48 horas",
    "Pedido recebido": "Pedido recebido",
    Categoria: "Categoria",
    "Usar template": "Usar template",
    Subcategorias: "Subcategorias",
    Todas: "Todas",
    "Nenhum template encontrado.": "Nenhum template encontrado.",
    "Você não possui um plano ativo. Para verificar os detalhes do seu plano, acesse:":
      "Você não possui um plano ativo. Para verificar os detalhes do seu plano, acesse:",
    "Para obter downloads agora mesmo:": "Para obter downloads agora mesmo:",
    "Obter downloads": "Obter downloads",
    "VER MAIS": "VER MAIS",
    "Meu plano": "Meu plano",
    "Você atingiu o limite de downloads esse mês ou seu pagamento não foi aprovado. Para verificar os detalhes do seu plano, acesse:":
      "Você atingiu o limite de downloads esse mês ou seu pagamento não foi aprovado. Para verificar os detalhes do seu plano, acesse:",
    "Para obter mais downloads agora mesmo:":
      "Para obter mais downloads agora mesmo:",
    "Obter mais downloads": "Obter mais downloads",
    "Você precisa escolher uma foto para a sua assinatura antes de usar um template.":
      "Você precisa escolher uma foto para a sua assinatura antes de usar um template.",
    "Escolher foto": "Escolher foto",
    "Tela redimensionada.": "Tela redimensionada.",
    "Ao redimensionar a tela do seu dispositivo você precisa recarregar o template novamente.":
      "Ao redimensionar a tela do seu dispositivo você precisa recarregar o template novamente.",
    "Template armazenado em 'Meus Templates'.":
      "Template armazenado em 'Meus Templates'.",
    "Não se preocupe, nossa equipe já está trabalhando para resolver.":
      "Não se preocupe, nossa equipe já está trabalhando para resolver.",
    "Seu template já está salvo.": "Seu template já está salvo.",
    "Enviar template": "Enviar template",
    Destinatário: "Destinatário",
    Assunto: "Assunto",
    "E-mail enviado com sucesso.": "E-mail enviado com sucesso.",
    Enviar: "Enviar",
    "Compartilhar via": "Compartilhar via",
    "Por favor, informe o destinatário.": "Por favor, informe o destinatário.",
    "Por favor, informe o assunto.": "Por favor, informe o assunto.",
    "Edite sua foto": "Edite sua foto",
    Pronto: "Pronto",
    Confirmar: "Confirmar",
    "Conte-nos o que está achando do aplicativo e quais categorias, templates gostaria de ver por aqui!":
      "Conte-nos o que está achando do aplicativo e quais categorias, templates gostaria de ver por aqui!",
    "Sua mensagem": "Sua mensagem",
    "Obrigado pela mensagem!": "Obrigado pela mensagem!",
    "Outros canais de contato:": "Outros canais de contato:",
    "Recuperação de senha": "Recuperação de senha",
    "Informe o seu e-mail": "Informe o seu e-mail",
    "Enviamos um código para o e-mail": "Enviamos um código para o e-mail",
    "Por favor, insira o código recebido no campo abaixo.":
      "Por favor, insira o código recebido no campo abaixo.",
    Código: "Código",
    "Digite a sua nova senha": "Digite a sua nova senha",
    "Confirme a sua senha": "Confirme a sua senha",
    "Senha alterada com sucesso.": "Senha alterada com sucesso.",
    "Faça login": "Faça login",
    "agora mesmo.": "agora mesmo.",
    "Trocar senha": "Trocar senha",
    "Usuário não encontrado em nosso sistema.":
      "Usuário não encontrado em nosso sistema.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "Parece que o código informado está incorreto. Verifique e tente novamente.",
    "Por favor, informe sua nova senha": "Por favor, informe sua nova senha",
    "Por favor, confirme a sua nova senha":
      "Por favor, confirme a sua nova senha",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente",
    "Resultado da busca por": "Resultado da busca por",
    "Voltar ao início": "Voltar ao início",
    "Por favor, digite a sua mensagem": "Por favor, digite a sua mensagem",
    "Você pode utilizar o aplicativo normalmente até":
      "Você pode utilizar o aplicativo normalmente até",
    "Após esta data você precisará renovar a sua assinatura":
      "Após esta data você precisará renovar a sua assinatura",
  },
  en: {
    "Ver todas as novidades": "See all news",
    Novidades: "News",
    "Algo deu errado ao tentar cancelar seu plano. Se o problema persistir, por favor, entre em contato com o suporte e informe sobre este erro.":
      "an unexpected error occurred while trying to cancel your plan. If this problem persists, please, contact our support team.",
    "a conversão exata para a sua moeda será feita pelo seu banco no momento do pagamento.":
      "the exact conversion to your currency will be done by your bank at the time of payment.",
    "Você poderá cancelar seu plano a qualquer momento.":
      "You can cancel your plan at any time.",
    "Dados de Pagamento": "Payment data",
    "Estado ou Província": "State or Province",
    Cancelada: "Canceled",
    "Já sou cadastrado": "I'm already registered",
    "Idiomas:": "Languages:",
    Senha: "Password",
    Entrar: "Log in",
    "Não sou cadastrado": "I'm not registered",
    Cadastrar: "Register",
    "Esqueceu sua senha?": "Forgot your password?",
    "Por favor, verifique se o e-mail digitado está correto.":
      "Please check that the email you entered is correct.",
    "Por favor, informe sua senha": "Please enter your password",
    "Usuário e/ou senha incorreto(s)": "Incorrect username or password",
    "Algo deu errado. Por favor, tente novamente em alguns instantes.":
      "Something went wrong. Please try again in a few moments.",
    Cadastro: "Register",
    "Código País": "Country Code",
    Passo: "Step",
    "Processando pagamento": "Processing payment",
    "Por favor, aguarde": "Please wait",
    "Dados pessoais": "Personal data",
    "Nome completo": "Full name",
    "Ex:": "E.g:",
    "Nome profissional": "Professional name",
    CPF: "Document",
    "Data de nascimento": "Date of birth",
    Telefone: "Telephone",
    CEP: "Zip code",
    UF: "State",
    Cidade: "City",
    Bairro: "Neighborhood",
    Endereço: "Address",
    Número: "Number",
    Gênero: "Gender",
    "Confirmar Senha": "Confirm Password",
    "Selecione uma opção": "Select an option",
    Feminino: "Female",
    Masculino: "Male",
    "Nível de Carreira": "Career Level",
    "Ao continuar você confirma que": "By continuing you confirm that",
    "leu e está de acordo com os nossos": "read and agree with ours",
    "termos de uso": "terms of use",
    Avançar: "Next",
    Planos: "Plans",
    mês: "month",
    "Assinatura mensal recorrente": "Monthly subscription",
    "Cupom de desconto": "Discount Coupon",
    "Validar Cupom": "Validate Coupon",
    "Escolha a forma de pagamento": "Choose your form of payment",
    "Cartão de Crédito": "Credit Card",
    Boleto: "Boleto",
    "Resumo do Pedido": "Order Summary",
    Nome: "Name",
    Plano: "Plan",
    "Descrição do plano": "Plan description",
    "Método de Pagamento": "Payment Method",
    "Dados do cartão": "Card details",
    "Número da residência": "House number",
    "Nome impresso no cartão": "Name printed on card",
    "Número do cartão": "Card number",
    "Bandeira do cartão": "Card flag",
    Vencimento: "Expiration",
    Mês: "Month",
    Ano: "Year",
    "Código de segurança": "Security code",
    Validade: "Validity",
    "Por favor, informe um cep válido": "Please enter a valid zip code",
    "Por favor, informe o seu nome inteiro": "Please enter your full name",
    "Por favor, informe o seu nome profissional":
      "Please enter your professional name",
    "Por favor, informe um e-mail válido": "Please enter a valid e-mail",
    "Por favor, informe o seu cpf corretamente":
      "Please enter your document correctly",
    "Por favor, informe sua data de nascimento corretamente":
      "Please enter your birthdate correctly",
    "Por favor, informe um número de telefone ou celular válido":
      "Please enter a valid phone or cell number",
    "Por favor, informe o seu cep": "Please enter your zip code",
    "Por favor, informe um UF válido": "Please enter a valid state",
    "Por favor, informe a cidade": "Please inform the city",
    "Por favor, informe o bairro": "Please inform the neighborhood",
    "Por favor, informe o seu endereço": "Please enter your address",
    "Por favor, informe o seu número": "Please enter your number",
    "Por favor, informe o seu gênero": "Please enter your gender",
    "Por favor, informe o seu nível de carreira":
      "Please enter your career level",
    "Por favor, confirme a sua senha": "Please confirm your password",
    "Suas senhas são diferentes. Por favor, digite-as novamente":
      "Your passwords are different. Please enter them again",
    "Por favor, selecione um plano para continuar":
      "Please select a plan to continue",
    "Por favor, informe o e-mail do dono do cartão":
      "Please enter the cardholder's email address",
    "Por favor, informe o cpf do dono o cartão":
      "Please inform the cardholder's document",
    "Por favor, informe a data de nascimento do dono do cartão":
      "Please inform the cardholder's birthdate",
    "Por favor, informe o número de telefone ou celular do dono do cartão":
      "Please enter the phone or cell number of the cardholder",
    "Por favor, informe o cep do dono do cartão":
      "Please enter the card owner's zip code",
    "Por favor, informe o UF do dono do cartão":
      "Please inform the cardholder's state",
    "Por favor, informe a cidade do dono do cartão":
      "Please inform the city of the cardholder",
    "Por favor, informe o bairro do dono do cartão":
      "Please inform the neighborhood of the cardholder",
    "Por favor, informe o endereço do dono do cartão":
      "Please enter the cardholder's address",
    "Por favor, informe o número do dono do cartão":
      "Please enter the number of the cardholder",
    "Por favor, informe o número do cartão": "Please enter the card number",
    "Por favor, informe a bandeira do cartão": "Please enter the card flag",
    "Por favor, informe o nome igual está escrito no cartão":
      "Please enter the same name that is written on the card",
    "Por favor, informe o mês de validade do cartão":
      "Please enter the month of validity of the card",
    "Por favor, informe o ano de validade do cartão no formato AAAA":
      "Please enter the year of validity of the card in YYYY format",
    "Por favor, informe o código de segurança do cartão":
      "Please enter the security code of the card",
    "Cadastro realizado com sucesso": "Successful registration",
    "Recebemos seu pedido": "We have received your order",
    "Você recebrá o boleto no": "You will receive the boleto on",
    "em até 48 horas": "within 48 hours",
    "Algo deu errado": "Something went wrong",
    "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente":
      "Something went wrong with your payment. Please check all details and try again",
    "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente.":
      "Something went wrong with your card validation. Please check all data and try again.",
    "Recebemos sua transação": "We received your transaction",
    "A confirmação do pagamento pode levar até 48 horas":
      "Payment confirmation can take up to 48 hours",
    "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone, pode manter o seu)":
      "If you are the credit card holder, leave this option checked. If the credit card belongs to someone else, deselect this option and fill in the fields below with the cardholder's personal details (email and phone, can keep yours)",
    Rua: "Street",
    "DD/MM/AAAA": "DD/MM/YYYY",
    "nome@dominio.com": "name@domain.com",
    AAAA: "YYYY",
    "Escolha uma categoria": "Choose a category",
    "Algo deu errado com o seu pagamento. Por favor, tente novamente indo em: 'menu - meus dados - plano' para liberar as funcionalidades do app":
      "Something went wrong with your payment. Please try again by going to: 'menu - my data - plan' to unlock the app's features",
    Início: "Start",
    "Meus Templates": "My Templates",
    "Próximos Lançamentos": "Next Launches",
    "Próximos lançamentos": "Next launches",
    "Minha Foto": "My Photo",
    "Meus Dados": "My Data",
    Sugestões: "Suggestions",
    Sair: "Exit",
    "O que você está buscando?": "What are you looking for?",
    Buscar: "Search",
    "Pagamento Pendente": "Payment Pending",
    "Ops. Nada por aqui.": "Oops. Nothing over here.",
    Compartilhar: "Share",
    Mover: "Move",
    "Criar Pasta": "Create Folder",
    Excluir: "Delete",
    "Renomear Pasta": "Rename Folder",
    "Excluir Pasta": "Delete Folder",
    "Pasta anterior": "Previous folder",
    "Mover template para:": "Move template to:",
    "Selecione uma pasta": "Select a folder",
    "Você precisa selecionar uma pasta": "You need to select a folder",
    "Deletar Template": "Delete Template",
    "Tem certeza que deseja excluir este template?":
      "Are you sure you want to delete this template?",
    CANCELAR: "CANCEL",
    Cancelar: "Cancel",
    SIM: "YES",
    "Novo nome para a pasta": "New name for the folder",
    RENOMEAR: "RENAME",
    "Por favor, informe o nome da pasta": "Please enter the name of the folder",
    "Deletar pasta": "Delete folder",
    "Tem certeza que deseja excluir a pasta":
      "Are you sure you want to delete the folder",
    "e todo o seu conteúdo?": "and all of its content?",
    "Nome da pasta": "Folder name",
    CRIAR: "CREATE",
    "Nenhum template encontrado": "No templates found",
    "Escolha uma foto para ser usada em sua assinatura:":
      "Choose a photo to use for your signature:",
    "Minha foto": "My photo",
    "Escolher imagem": "Choose image",
    "Você só pode alterar a imagem da sua assinatura a cada 30 dias.":
      "You can only change your subscription image every 30 days.",
    Salvar: "Save",
    "Assinatura atualizada com sucesso.": "Subscription updated successfully.",
    "Se o problema persistir, por favor, informe a equipe Do It.":
      "If the problem persists, please inform the Do It team.",
    Perfil: "Profile",
    "Meu perfil": "My profile",
    "Dados atualizados. Obrigado.": "Data updated. Thank you.",
    Atualizar: "Update",
    "Por favor, informe o seu estado": "Please enter your state",
    "Por favor, informe a sua cidade": "Please inform your city",
    "Por favor, informe o seu bairro": "Please inform your neighborhood",
    "Detalhes do plano": "Plan details",
    Assinatura: "Signature",
    Pago: "Paid",
    "Aguardando Pagamento": "Awaiting Payment",
    "do plano": "from the plan",
    Avulsos: "Loose",
    "Renovação em": "Renewal in",
    Usuário: "User",
    "Obter mais downloads de templates": "Get more template downloads",
    "Ative a sua assinatura para ter acesso a todas as funcionalidades do aplicativo.":
      "Activate your subscription to have access to all the functionality of the application.",
    "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual.":
      "If you want to change your plan, or your credit card details, you will need to cancel your current plan first.",
    "Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão.":
      "You will still be able to use the app for the days referring to your last payment. After these days have passed, you will be able to choose a new plan and / or change your card details.",
    "Caso você esteja utilizando algum cupom, no cancelamento da assinatura ele também será cancelado, mas você poderá utilizar um novo cupom - caso tenha acesso a algum que esteja vigente na data de escolha do novo plano.":
      "If you are using a coupon, when canceling your subscription it will also be canceled, but you will be able to use a new coupon - if you have access to one that is in effect on the date of choosing the new plan.",
    "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail":
      "If you have made a payment more than 48 hours ago and it has not yet been processed, please check your email to see what happened or contact us by email",
    "Seu plano foi cancelado.": "Your plan has been canceled.",
    "Cancelar Assinatura": "Cancel Subscription",
    "Ativar Assinatura": "Activate Subscription",
    "neste mês": "this month",
    Ilimitado: "Unlimited",
    "CANCELAR ASSINATURA": "CANCEL SUBSCRIPTION",
    "Tem certeza que deseja cancelar a sua assinatura? Esta ação não poderá ser desfeita.":
      "Are you sure you want to cancel your subscription? This action cannot be undone.",
    NÃO: "NO",
    "Alterar senha": "Change password",
    "Alterar Senha": "Change Password",
    "Senha Atual": "Current Password",
    "Nova Senha": "New Password",
    "Confirmar Nova Senha": "Confirm New Password",
    "Sua senha foi alterada com sucesso.":
      "Your password has been changed successfully.",
    "Sua nova senha está diferente da confirmação de senha.":
      "Your new password is different from password confirmation.",
    "Por favor, confirme a sua senha nova": "Please confirm your new password",
    "Por favor, informe a sua senha nova": "Please enter your new password",
    "Por favor, informe a sua senha atual":
      "Please enter your current password",
    "Templates Extras": "Extra Templates",
    "Escolha o pacote que melhor lhe atende e não fique sem os templates que você quer!":
      "Choose the package that best suits you and don't be without the templates you want!",
    "Os download extras de templates adquiridos aqui não possuem prazo de validade. Duram até você usar todos.":
      "Extra template downloads purchased here have no expiration date. They last until you use them all.",
    templates: "templates",
    "E-mail": "E-mail",
    "Comprar Pacote": "Buy Package",
    "Seu pagamento está sendo processado. Isso pode levar até 48 horas":
      "Your payment is being processed. This can take up to 48 hours",
    "Pedido recebido": "Order received",
    Categoria: "Category",
    "Usar template": "Use template",
    Subcategorias: "Subcategories",
    Todas: "All",
    "Nenhum template encontrado.": "No templates found.",
    "Você não possui um plano ativo. Para verificar os detalhes do seu plano, acesse:":
      "You don't have an active plan. To check your plan details, visit:",
    "Para obter downloads agora mesmo:": "To get downloads right now:",
    "Obter downloads": "Get downloads",
    "VER MAIS": "SEE MORE",
    "Meu plano": "My plan",
    "Você atingiu o limite de downloads esse mês ou seu pagamento não foi aprovado. Para verificar os detalhes do seu plano, acesse:":
      "You have reached your download limit this month or your payment has not been approved. To check your plan details, visit:",
    "Para obter mais downloads agora mesmo:":
      "To get more downloads right now:",
    "Obter mais downloads": "Get more downloads",
    "Você precisa escolher uma foto para a sua assinatura antes de usar um template.":
      "You need to choose a photo for your signature before using a template.",
    "Escolher foto": "Choose photo",
    "Tela redimensionada.": "Screen resized.",
    "Ao redimensionar a tela do seu dispositivo você precisa recarregar o template novamente.":
      "When resizing your device's screen you need to reload the template again.",
    "Template armazenado em 'Meus Templates'.":
      "Template stored in 'My Templates'.",
    "Não se preocupe, nossa equipe já está trabalhando para resolver.":
      "Don't worry, our team is already working on it.",
    "Seu template já está salvo.": "Your template is already saved.",
    "Enviar template": "Submit template",
    Destinatário: "Recipient",
    Assunto: "Subject",
    "E-mail enviado com sucesso.": "E-mail sent successfully.",
    Enviar: "Send",
    "Compartilhar via": "Share via",
    "Por favor, informe o destinatário.": "Please inform the recipient.",
    "Por favor, informe o assunto.": "Please enter the subject.",
    "Edite sua foto": "Edit your photo",
    Pronto: "Ready",
    Confirmar: "Confirm",
    "Conte-nos o que está achando do aplicativo e quais categorias, templates gostaria de ver por aqui!":
      "Tell us what you think of the application and what categories, templates you would like to see here!",
    "Sua mensagem": "Your message",
    "Obrigado pela mensagem!": "Thanks for the message!",
    "Outros canais de contato:": "Other contact channels:",
    "Recuperação de senha": "Password recovery",
    "Informe o seu e-mail": "Enter your e-mail",
    "Enviamos um código para o e-mail": "We sent a code to the e-mail",
    "Por favor, insira o código recebido no campo abaixo.":
      "Please enter the code received in the field below.",
    Código: "Code",
    "Digite a sua nova senha": "Enter your new password",
    "Confirme a sua senha": "Confirm your password",
    "Senha alterada com sucesso.": "Password changed successfully.",
    "Faça login": "Sign in",
    "agora mesmo.": "right now.",
    "Trocar senha": "Change password",
    "Usuário não encontrado em nosso sistema.": "User not found in our system.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "It looks like the code you entered is incorrect. Please check and try again.",
    "Por favor, informe sua nova senha": "Please enter your new password",
    "Por favor, confirme a sua nova senha": "Please confirm your new password",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Your password and password confirmation are different. Please check and try again",
    "Resultado da busca por": "Search results for",
    "Voltar ao início": "Back to home",
    "Por favor, digite a sua mensagem": "Please type your message",
    "Você pode utilizar o aplicativo normalmente até":
      "You can use the app normally until",
    "Após esta data você precisará renovar a sua assinatura":
      "After this date you will need to renew your subscription",
    "20 posts poderosos para você profissionalizar seu negócio!":
      "20 powerful posts for you to professionalize your business!",
    "50 posts poderosos para você profissionalizar seu negócio!":
      "50 powerful posts for you to professionalize your business!",
    "Direito a utilização do app de forma ILIMITADA. Todos os templates a sua disposição quantas vezes você quiser usar!":
      "UNLIMITED use of the app. All the templates at your disposal as many times as you want to use!",
  },
  es: {
    "Algo deu errado ao tentar cancelar seu plano. Se o problema persistir, por favor, entre em contato com o suporte e informe sobre este erro.":
      "Se produjo un error al intentar cancelar tu plan. Si el problema persiste, comuníquese con el servicio de asistencia y notifique este error.",
    "Ver todas as novidades": "Ver todas las novedades",
    Novidades: "Nuevos",
    "Algo deu errado ao tentar cancelar seu plano. Se o problema persistir, por favor, entre em contato com o suporte e informe sobre este erro.":
      "Se produjo un error al intentar cancelar tu plan. Si el problema persiste, comuníquese con el servicio de asistencia y notifique este error.",
    "a conversão exata para a sua moeda será feita pelo seu banco no momento do pagamento.":
      "la conversión exacta a tu moneda será la realizada por tu banco en el momento del pago..",
    "Você poderá cancelar seu plano a qualquer momento.":
      "Puedes darte baja en cualquier momento.",
    "Dados de Pagamento": "Datos de pago",
    "Estado ou Província": "Estado/Departamento/Provincia",
    Cancelada: "Cancelada",
    "Já sou cadastrado": "Ya estoy registrado",
    "Idiomas:": "Idiomas:",
    Senha: "Contraseña",
    Entrar: "Iniciar sesión",
    "Não sou cadastrado": "No estoy registrado",
    Cadastrar: "Registrar",
    "Esqueceu sua senha?": "¿Olvidaste tu contraseña?",
    "Por favor, verifique se o e-mail digitado está correto.":
      "Verifica que el correo electrónico que ingresaste sea correcto.",
    "Por favor, informe sua senha": "Por favor, introduce tu contraseña",
    "Usuário e/ou senha incorreto(s)":
      "Nombre de usuario o contraseña incorrecta",
    "Algo deu errado. Por favor, tente novamente em alguns instantes.":
      "Algo salió mal. Inténtalo de nuevo en unos momentos.",
    Cadastro: "Inscripción",
    "Código País": "Código País",
    Passo: "Paso",
    "Processando pagamento": "Procesando el pago",
    "Por favor, aguarde": "Espera, por favor.",
    "Dados pessoais": "Datos personales",
    "Nome completo": "Nombre completo",
    "Ex:": "Ej:",
    "Nome profissional": "Nombre profesional",
    CPF: "Documento",
    "Data de nascimento": "Fecha de nacimiento",
    Telefone: "Teléfono",
    CEP: "Código postal",
    UF: "Estado/Departamento/Provincia",
    Cidade: "Ciudad",
    Bairro: "Urbanización/Barrio",
    Endereço: "Dirección",
    Número: "Número",
    Gênero: "Género",
    "Confirmar Senha": "Confirmar contraseña",
    "Selecione uma opção": "Seleccionar una opción",
    Feminino: "Femenino",
    Masculino: "Masculino",
    "Nível de Carreira": "Nivel de Carrera",
    "Nível de carreira": "Nivel de carrera",
    "Ao continuar você confirma que": "Al continuar, confirma que",
    "leu e está de acordo com os nossos":
      "leer y estar de acuerdo con nuestras",
    "termos de uso": "condiciones de uso",
    Avançar: "Siguiente",
    Planos: "Planes",
    mês: "mes",
    "Assinatura mensal recorrente": "Suscripción mensual",
    "Cupom de desconto": "Cupón de descuento",
    "Validar Cupom": "Validar cupón",
    "Escolha a forma de pagamento": "Elige tu forma de pago",
    "Cartão de Crédito": "Tarjeta de crédito",
    Boleto: "Pago en efectivo",
    "Resumo do Pedido": "Resumen del pedido",
    Nome: "Nombre",
    Plano: "Plan",
    "Descrição do plano": "Descripción del plan",
    "Método de Pagamento": "Método de pago",
    "Dados do cartão": "Detalles de la tarjeta",
    "Número da residência": "Número de la casa",
    "Nome impresso no cartão": "Nombre impreso en la tarjeta",
    "Número do cartão": "Número de la tarjeta",
    "Bandeira do cartão": "Marca de la tarjeta",
    Vencimento: "Caducidad",
    Mês: "Mes",
    Ano: "Año",
    "Código de segurança": "Código de seguridad",
    Validade: "Fecha de vencimiento",
    "Por favor, informe um cep válido":
      "Por favor, ingresa un código postal válido",
    "Por favor, informe o seu nome inteiro":
      "Por favor, ingresa tu nombre completo",
    "Por favor, informe o seu nome profissional":
      "Por favor, ingresa tu nombre profesional",
    "Por favor, informe um e-mail válido":
      "Por favor, ingresa una dirección de correo electrónico válida",
    "Por favor, informe o seu cpf corretamente":
      "Por favor, ingresa tu documento correctamente",
    "Por favor, informe sua data de nascimento corretamente":
      "Por favor, ingresa tu fecha de nacimiento correctamente",
    "Por favor, informe um número de telefone ou celular válido":
      "Por favor, ingresa un número de teléfono o celular válido",
    "Por favor, informe o seu cep": "Por favor, ingresa tu código postal",
    "Por favor, informe um UF válido": "Por favor, ingresa un estado válido",
    "Por favor, informe a cidade": "Por favor, informa la ciudad",
    "Por favor, informe o bairro": "Por favor, informa la urbanización/barrio",
    "Por favor, informe o seu endereço": "Por favor, introduce tu dirección",
    "Por favor, informe o seu número": "Por favor, ingresa tu número",
    "Por favor, informe o seu gênero": "Por favor, ingresa tu género",
    "Por favor, informe o seu nível de carreira":
      "Por favor, ingresa tu nivel de carrera",
    "Por favor, confirme a sua senha": "Por favor, confirma tu contraseña",
    "Suas senhas são diferentes. Por favor, digite-as novamente":
      "Tus contraseñas son diferentes. Por favor, vuelva a ingresarlas",
    "Por favor, selecione um plano para continuar":
      "Por favor, selecciona un plan para continuar",
    "Por favor, informe o e-mail do dono do cartão":
      "Por favor, introduce la dirección del correo electrónico del titular de la tarjeta",
    "Por favor, informe o cpf do dono o cartão":
      "Por favor, informa el documento del propietario de la tarjeta",
    "Por favor, informe a data de nascimento do dono do cartão":
      "Por favor, indica la fecha de nacimiento del titular de la tarjeta",
    "Por favor, informe o número de telefone ou celular do dono do cartão":
      "Por favor, ingresa el número de teléfono o celular del titular de la tarjeta",
    "Por favor, informe o cep do dono do cartão":
      "Por favor, ingresa el código postal del propietario de la tarjeta",
    "Por favor, informe o UF do dono do cartão":
      "Por favor, informa el Estado/Departamento/Provincia del titular de la tarjeta",
    "Por favor, informe a cidade do dono do cartão":
      "Por favor, informa la ciudad del titular de la tarjeta",
    "Por favor, informe o bairro do dono do cartão":
      "Por favor, informa la urbanización/barrio del titular de la tarjeta",
    "Por favor, informe o endereço do dono do cartão":
      "Por favor, introduce  la dirección del titular de la tarjeta",
    "Por favor, informe o número do dono do cartão":
      "Por favor, introduce el número del titular de la tarjeta",
    "Por favor, informe o número do cartão":
      "Por favor, introduzca el número de tarjeta",
    "Por favor, informe a bandeira do cartão":
      "Por favor, introduzca la bandera de la tarjeta",
    "Por favor, informe o nome igual está escrito no cartão":
      "Por favor, introduce el mismo nombre que está escrito en la tarjeta",
    "Por favor, informe o mês de validade do cartão":
      "Por favor, ingresa el mes de validez de la tarjeta",
    "Por favor, informe o ano de validade do cartão no formato AAAA":
      "Por favor, introduce el año de validez de la tarjeta en formato AAAA",
    "Por favor, informe o código de segurança do cartão":
      "Por favor, ingresa el código de seguridad de la tarjeta",
    "Cadastro realizado com sucesso": "Registro exitoso",
    "Recebemos seu pedido": "Hemos recibido tu pedido",
    "Você recebrá o boleto no": "Recibirás el cupón",
    "em até 48 horas": "hasta en 48 horas",
    "Algo deu errado": "Algo salió mal",
    "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente":
      "Se produjo un error con el pago. Por favor, verifica todos los detalles y vuelve a intentarlo",
    "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente.":
      "Algo salió mal con la validación de tu tarjeta. Verifica todos los datos y vuelve a intentarlo.",
    "Recebemos sua transação": "Recibimos tu transacción",
    "A confirmação do pagamento pode levar até 48 horas":
      "La confirmación del pago puede demorar hasta 48 horas",
    "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone, pode manter o seu)":
      "Si eres el titular de la tarjeta de crédito, deja esta opción marcada. Si la tarjeta de crédito pertenece a otra persona, desmarca esta opción y completa los campos a continuación con los datos personales del titular de la tarjeta (correo electrónico y teléfono, puedes conservar los tuyos)",
    Rua: "Calle",
    "DD/MM/AAAA": "DD/MM/AAAA",
    "nome@dominio.com": "nombre@dominio.com",
    AAAA: "AAAA",
    "Escolha uma categoria": "Elige una categoría",
    "Algo deu errado com o seu pagamento. Por favor, tente novamente indo em: 'menu - meus dados - plano' para liberar as funcionalidades do app":
      "Algo salió mal con su pago. Vuelva a intentarlo yendo a: 'menú - mis datos - plan' para desbloquear las funciones de la aplicación",
    Início: "Inicio",
    "Meus Templates": "Mis diseños",
    "Próximos Lançamentos": "Próximos Lanzamientos",
    "Próximos lançamentos": "Próximos lanzamientos",
    "Minha Foto": "Mi Foto",
    "Meus Dados": "Mis Datos",
    Sugestões: "Sugerencias",
    Sair: "Salir",
    "O que você está buscando?": "¿Qué estás buscando?",
    Buscar: "Buscar",
    "Pagamento Pendente": "Pago pendiente",
    "Ops. Nada por aqui.": "¡Huy! No hay nada por aquí.",
    Compartilhar: "Comparte",
    Mover: "Muévete",
    "Criar Pasta": "Crear carpeta",
    Excluir: "Eliminar",
    "Renomear Pasta": "Cambiar nombre de carpeta",
    "Excluir Pasta": "Eliminar carpeta",
    "Pasta anterior": "Carpeta anterior",
    "Mover template para:": "Mover diseño a:",
    "Selecione uma pasta": "Seleccionar una carpeta",
    "Você precisa selecionar uma pasta": "Debe seleccionar una carpeta",
    "Deletar Template": "Eliminar diseño",
    "Tem certeza que deseja excluir este template?":
      "¿¿Estás seguro que deseas eliminar este diseño??",
    CANCELAR: "CANCELAR",
    Cancelar: "Cancelar",
    SIM: "SÍ",
    "Novo nome para a pasta": "Nuevo nombre de la carpeta",
    RENOMEAR: "CAMBIAR EL NOMBRE",
    "Por favor, informe o nome da pasta": "Introduzca el nombre de la carpeta",
    "Deletar pasta": "Eliminar carpeta",
    "Tem certeza que deseja excluir a pasta":
      "¿Está seguro que deseas eliminar la carpeta",
    "e todo o seu conteúdo?": "y todo su contenido?",
    "Nome da pasta": "Nombre de la carpeta",
    CRIAR: "CREAR",
    "Nenhum template encontrado": "No se encontraron diseños",
    "Escolha uma foto para ser usada em sua assinatura:":
      "Elige una foto para usar en tu firma:",
    "Minha foto": "Mi foto",
    "Escolher imagem": "Elegir imagen",
    "Você só pode alterar a imagem da sua assinatura a cada 30 dias.":
      "Solo puede cambiar la imagen de su suscripción cada 30 días",
    "Você só pode alterar a imagem da sua assinatura uma vez a cada 30 dias.":
      "Solo puede cambiar la imagen de su suscripción una vez cada 30 días",
    Salvar: "Guardar",
    "Assinatura atualizada com sucesso.":
      "Suscripción actualizada correctamente.",
    "Se o problema persistir, por favor, informe a equipe Do It.":
      "Si el problema persiste, informa al equipo de Do It.",
    Perfil: "Perfil",
    "Meu perfil": "Mi perfil",
    "Dados atualizados. Obrigado.": "Datos actualizados. Gracias.",
    Atualizar: "Actualizar",
    "Por favor, informe o seu estado":
      "Por favor, informa tu estado/departamento/provincia",
    "Por favor, informe a sua cidade": "Por favor, informa a tu ciudad",
    "Por favor, informe o seu bairro":
      "Por favor, informa tu urbanización/barrio",
    "Detalhes do plano": "Detalles del plano",
    Assinatura: "Firma",
    Pago: "Pagado",
    "Aguardando Pagamento": "En espera de pago",
    "do plano": "del plan",
    Avulsos: "Suelto",
    "Renovação em": "Renovación en",
    Usuário: "Usuario",
    "Obter mais downloads de templates": "Obtener más descargas de diseños",
    "Ative a sua assinatura para ter acesso a todas as funcionalidades do aplicativo.":
      "Activa tu suscripción para tener acceso a todas las funciones de la aplicación.",
    "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual.":
      "Si deseas cambiar tu plan o los detalles de tu tarjeta de crédito, primero deberás cancelar tu plan actual.",
    "Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão.":
      "Aún puedes usar la aplicación durante los días de tu último pago. Una vez que estos días terminen, puedes elegir un nuevo plan y / o cambiar los detalles de tu tarjeta.",
    "Caso você esteja utilizando algum cupom, no cancelamento da assinatura ele também será cancelado, mas você poderá utilizar um novo cupom - caso tenha acesso a algum que esteja vigente na data de escolha do novo plano.":
      "Si estás utilizando cualquier cupón, al cancelar tu suscripción también se cancelará, pero podrás utilizar un nuevo cupón, si tienes acceso a uno que esté en vigor en la fecha de elección del nuevo plan.",
    "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail":
      "Si realizaste un pago hace más de 48 horas y aún no se ha procesado, consulta tu correo electrónico para ver qué sucedió o comunícate con nosotros por correo electrónico",
    "Seu plano foi cancelado.": "Su plan ha sido cancelado.",
    "Cancelar Assinatura": "Cancelar suscripción",
    "Ativar Assinatura": "Activar suscripción",
    "neste mês": "este mes",
    Ilimitado: "Ilimitado",
    "CANCELAR ASSINATURA": "CANCELAR SUSCRIPCIÓN",
    "Tem certeza que deseja cancelar a sua assinatura? Esta ação não poderá ser desfeita.":
      "¿Está seguro que deseas cancelar tu suscripción? Esta acción no se puede deshacer.",
    NÃO: "NO",
    "Alterar senha": "Cambiar contraseña",
    "Senha Atual": "Contraseña actual",
    "Nova Senha": "Nueva contraseña",
    "Confirmar Nova Senha": "Confirmar nueva contraseña",
    "Sua senha foi alterada com sucesso.":
      "Tu contraseña ha sido cambiada exitosamente.",
    "Sua nova senha está diferente da confirmação de senha.":
      "Tu nueva contraseña es diferente a la confirmación de contraseña.",
    "Por favor, confirme a sua senha nova":
      "Por favor, confirma tu nueva contraseña",
    "Por favor, informe a sua senha nova":
      "Por favor, ingresa tu nueva contraseña",
    "Por favor, informe a sua senha atual":
      "Por favor, ingresa tu contraseña actual",
    "Templates Extras": "Diseños adicionales",
    "Escolha o pacote que melhor lhe atende e não fique sem os templates que você quer!":
      "¡Elige el paquete que más se adapte a ti y no te quedes sin los diseños que deseas!",
    "Os download extras de templates adquiridos aqui não possuem prazo de validade. Duram até você usar todos.":
      "Las descargas de diseños adicionales compradas aquí no tienen fecha de caducidad. Duran hasta que las usan todas.",
    templates: "diseños",
    "E-mail": "Correo electrónico",
    "Comprar Pacote": "Comprar paquete",
    "Seu pagamento está sendo processado. Isso pode levar até 48 horas":
      "Tu pago se está procesando. Esto puede demorar hasta 48 horas",
    "Pedido recebido": "Pedido recibido",
    Categoria: "Categoría",
    "Usar template": "Usar plantilla",
    Subcategorias: "Subcategorías",
    Todas: "Todas",
    "Nenhum template encontrado.": "No se encontraron diseños",
    "Você não possui um plano ativo. Para verificar os detalhes do seu plano, acesse:":
      "No tienes un plan activo. Para verificar los detalles de tu plan, visita:",
    "Para obter downloads agora mesmo:": "Para obtener descargas ahora mismo:",
    "Obter downloads": "Obtener descargas",
    "VER MAIS": "VER MÁS",
    "Meu plano": "Mi plan",
    "Meu Plano": "Mi Plan",
    "Você atingiu o limite de downloads esse mês ou seu pagamento não foi aprovado. Para verificar os detalhes do seu plano, acesse:":
      "Haz alcanzado tu límite de descarga este mes o tu pago no ha sido aprobado. Para verificar los detalles de tu plan, visita:",
    "Para obter mais downloads agora mesmo:":
      "Para obtener más descargas ahora mismo:",
    "Obter mais downloads": "Obtener más descargas",
    "Você precisa escolher uma foto para a sua assinatura antes de usar um template.":
      "Debes elegir una foto para tu firma antes de usar una plantilla.",
    "Escolher foto": "Elegir foto",
    "Tela redimensionada.": "Pantalla redimensionada.",
    "Ao redimensionar a tela do seu dispositivo você precisa recarregar o template novamente.":
      "Al cambiar el tamaño de la pantalla de tu dispositivo, debes volver a cargar el diseño.",
    "Template armazenado em 'Meus Templates'.":
      "Diseño almacenado en 'Mis Diseños'.",
    "Não se preocupe, nossa equipe já está trabalhando para resolver.":
      "No te preocupes, nuestro equipo ya está trabajando para solucionarlo.",
    "Seu template já está salvo.": "Tu diseño ya está guardado.",
    "Enviar template": "Enviar diseño",
    Destinatário: "Destinatario",
    Assunto: "Asunto",
    "E-mail enviado com sucesso.": "Correo electrónico enviado correctamente.",
    Enviar: "Enviar",
    "Compartilhar via": "Compartir comprobante",
    "Por favor, informe o destinatário.": "Por favor, informa el destinatario.",
    "Por favor, informe o assunto.": "Por favor, introduce el asunto.",
    "Edite sua foto": "Edita tu foto",
    Pronto: "Listo",
    Confirmar: "Confirmar",
    "Conte-nos o que está achando do aplicativo e quais categorias, templates gostaria de ver por aqui!":
      "¡Díganos qué piensas de la aplicación y qué categorías, diseños te gustaría ver aquí!",
    "Sua mensagem": "Tu mensaje",
    "Obrigado pela mensagem!": "¡Gracias por el mensaje!",
    "Outros canais de contato:": "Otros canales de contacto:",
    "Recuperação de senha": "Recuperación de contraseña",
    "Informe o seu e-mail": "Ingresa tu correo electrónico",
    "Enviamos um código para o e-mail":
      "Enviamos un código a tu correo electrónico",
    "Por favor, insira o código recebido no campo abaixo.":
      "Por favor, ingresa el código recibido en el campo de abajo.",
    Código: "Código",
    "Digite a sua nova senha": "Ingresa tu nueva contraseña",
    "Confirme a sua senha": "Confirma tu contraseña",
    "Senha alterada com sucesso.": "La contraseña se cambió correctamente.",
    "Faça login": "Iniciar sesión",
    "agora mesmo.": "ahora mismo.",
    "Trocar senha": "Cambiar contraseña",
    "Usuário não encontrado em nosso sistema.":
      "Usuario no encontrado en nuestro sistema.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "Parece que el código que ingresaste es incorrecto. Verifícalo y vuelve a intentarlo.",
    "Por favor, informe sua nova senha":
      "Por favor, introduce tu nueva contraseña",
    "Por favor, confirme a sua nova senha":
      "Por favor, confirma tu nueva contraseña",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Tu contraseña y la confirmación de la contraseña son diferentes. Verifica y vuelve a intentarlo",
    "Resultado da busca por": "Resultados de la búsqueda de",
    "Voltar ao início": "Volver al principio",
    "Por favor, digite a sua mensagem": "Por favor, escribe tu mensaje",
    "Você pode utilizar o aplicativo normalmente até":
      "Puedes usar la aplicación normalmente hasta",
    "Após esta data você precisará renovar a sua assinatura":
      "Después de esta fecha, deberás renovar tu suscripción",
    "20 posts poderosos para você profissionalizar seu negócio!":
      "¡20 publicaciones poderosas para que profesionalices tu negocio!",
    "50 posts poderosos para você profissionalizar seu negócio!":
      "¡50 publicaciones poderosas para que profesionalices tu negocio!",
    "Direito a utilização do app de forma ILIMITADA. Todos os templates a sua disposição quantas vezes você quiser usar!":
      "Uso ILIMITADO de la aplicación. ¡Todas las publicaciones a tu disposición las veces que quieras usarlas!",
  },
};
