import { render, staticRenderFns } from "./Sugestoes.vue?vue&type=template&id=d003f074&scoped=true&"
import script from "./Sugestoes.vue?vue&type=script&lang=js&"
export * from "./Sugestoes.vue?vue&type=script&lang=js&"
import style0 from "./Sugestoes.vue?vue&type=style&index=0&id=d003f074&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d003f074",
  null
  
)

export default component.exports