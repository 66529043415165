<template>
	<div class="container pb-55-mobile container500">
		<div class="mb-2-desktop"></div>
		<h1 class="title my-1">{{ t("Meu perfil") }}</h1>

		<!-- <div class="input-holder labeled login first desktop12 tablet12 mobile12">
      <p class="text-desc text-brown text-bold">Nome completo *</p>
      <img src="../assets/images/icon-user.png" />
      <input v-model="user.name" type="text" placeholder="Ex: Maria da Silva" />
    </div> -->

		<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
			<p class="text-desc text-brown text-bold">
				{{ t("Nome profissional") }} *
			</p>
			<img src="../assets/images/icon-user.png" />
			<input
				v-model="user.professionalName"
				type="text"
				:placeholder="t('Ex:') + ' Dra Maria'"
			/>
		</div>

		<!-- <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
      <p class="text-desc text-brown text-bold">E-mail *</p>
      <img class="icon-pwd" src="../assets/images/icon-pwd.png" />
      <input v-model="user.email" type="email" placeholder="Ex: meuemail@dominio.com" />
    </div> -->

		<!-- <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
      <p class="text-desc text-brown text-bold">CPF *</p>
      <img class="icon-cpf" src="../assets/images/icon-cpf.png" />
      <input v-model="user.cpf" type="text" placeholder="Ex: 12345678901" />
    </div> -->

		<!-- <div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
      <p class="text-desc text-brown text-bold">Data de nascimento *</p>
      <img src="../assets/images/icon-birthday.png" />
      <input v-model="user.birthday" type="date" placeholder="Ex: 01/01/1980" />
    </div> -->

		<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
			<p class="text-desc text-brown text-bold">{{ t("Telefone") }} *</p>
			<img src="../assets/images/icon-phone.png" />
			<input
				v-model="user.phone"
				type="text"
				:placeholder="t('Ex:') + ' 11 98765 4321'"
			/>
		</div>

		<div
			class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
		>
			<p class="text-desc text-brown text-bold">
				{{ t("Nível de Carreira") }} *
			</p>
			<img src="../assets/images/icon-user.png" />
			<select v-model="user.career">
				<option value>{{ t("Selecione uma opção") }}</option>
				<option v-for="career in careers" :key="career.id" :value="career">{{
					career[user.genre]
				}}</option>
			</select>
		</div>

		<div v-show="!isForeign" class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
			<p class="text-desc text-brown text-bold">{{ t("CEP") }} *</p>
			<img class="icon-address" src="../assets/images/icon-address.png" />
			<the-mask
				@blur.native="getCep()"
				v-model="user.zipcode"
				:mask="['#####-###']"
				:masked="false"
				:placeholder="t('Ex:') + ' 12345-678'"
			/>
		</div>

		<transition enter-active-class="animated fadeIn">
			<div v-show="!isForeign">
				<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
					<p class="text-desc text-brown text-bold">{{ t("UF") }} *</p>
					<img class="icon-address" src="../assets/images/icon-address.png" />
					<input
						v-model="user.state"
						type="text"
						:placeholder="t('Ex:') + ' SP'"
					/>
				</div>
				<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
					<p class="text-desc text-brown text-bold">{{ t("Cidade") }} *</p>
					<img class="icon-address" src="../assets/images/icon-address.png" />
					<input v-model="user.city" type="text" />
				</div>
				<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
					<p class="text-desc text-brown text-bold">{{ t("Bairro") }} *</p>
					<img class="icon-address" src="../assets/images/icon-address.png" />
					<input v-model="user.neighborhood" type="text" />
				</div>
				<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
					<p class="text-desc text-brown text-bold">{{ t("Endereço") }} *</p>
					<img class="icon-address" src="../assets/images/icon-address.png" />
					<input
						v-model="user.address"
						type="text"
						:placeholder="t('Ex:') + ' ' + t('Rua') + ' Brigadeiro, 123, SP'"
					/>
				</div>
				<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
					<p class="text-desc text-brown text-bold">{{ t("Número") }} *</p>
					<img class="icon-address" src="../assets/images/icon-address.png" />
					<input v-model="user.number" type="text" placeholder />
				</div>
			</div>
		</transition>

		<transition
			enter-active-class="animated fadeInDown"
			leave-active-class="animated fadeOutDown"
		>
			<p v-show="updateSuccess" class="text-desc text-center text-blue mt-2">
				{{ t("Dados atualizados. Obrigado.") }}
			</p>
		</transition>

		<transition enter-active-class="animated fadeIn">
			<p
				v-show="warningMessage != ''"
				class="text-desc text-center text-blue mt-2"
			>
				{{ warningMessage }}
			</p>
		</transition>

		<button @click="updateUser()" class="default bg-brown mt-2 mb-2">
			<span v-show="!loading">{{ t("Atualizar") }}</span>
			<div v-show="loading" class="loading-spinner"></div>
		</button>
	</div>
</template>
<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	import validators from "../util/validators";
	export default {
		name: "Profile",
		data() {
			return {
				warningMessage: "",
				loading: false,
				updateSuccess: false,
				careers: [],
				user: {
					name: "",
					email: "",
					image: "",
					cpf: "",
					phone: "",
					address: "",
					professionalName: "",
					consultantCode: "",
					principalName: "",
					career: "",
					password: "",
					password2: "",
					birthday: "",
					zipcode: "",
				},
			};
		},
		created() {
			http
				.get("user/details", authentication.getHeaders())
				.then((response) => {
					this.user = response.data;
					this.user.birthday = this.$moment(this.user.birthday).format(
						"YYYY-MM-DD"
					);
					this.getCareers(this.user.career.language);
				})
				.catch((err) => {
					console.log(err);
					if (err.response.status === 401) this.$router.push("login");
				});			
		},
		computed: {
			isForeign() {
				if (this.user && this.user.career) {
					return this.user.career.language != "Portuguese";
				} else {
					return false;
				}
			},
		},
		methods: {
			getCareers(language) {
				http.get("career?take=-1&language=" + language).then((response) => {
					this.careers = response.data.list;
				});
			},
			getCep() {
				this.user.zipcode = this.user.zipcode.replace(/[^\d]/g, "");

				if (this.user.zipcode.length != 8) {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe um cep válido"
					];
					return;
				} else {
					http
						.get(`https://viacep.com.br/ws/${this.user.zipcode}/json/`)
						.then((response) => {
							this.user.state = response.data.uf;
							this.user.city = response.data.localidade;
							this.user.neighborhood = response.data.bairro;
							this.user.address = response.data.logradouro;
						})
						.catch((err) => {
							console.log(err);
						})
						.finally(() => {
							this.showFullAddress = true;
						});
				}
			},
			updateUser() {
				if (!this.user.professionalName) {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe o seu nome profissional"
					];
					return;
				}
				if (this.user.career == "") {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe o seu nível de carreira"
					];
					return;
				}				
				if (this.user.phone == "") {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe um número de telefone ou celular válido"
					];
					return;
				}
				if(!this.isForeign) {
					if (!validators.validateFullName(this.user.name)) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe o seu nome completo"
						];
						return;
					}
					if (!validators.validateEmail(this.user.email)) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe um e-mail válido"
						];
						return;
					}
					if (
						!validators.validateCPF(this.user.cpf) &&
						!authentication.getUser().info.premium
					) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe o seu cpf corretamente"
						];
						return;
					}
					if (this.user.birthday == "") {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe sua data de nascimento"
						];
						return;
					}
					if (!this.user.zipcode && !authentication.getUser().info.premium) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe o seu CEP"
						];
						return;
					}
					if (!this.user.state && !authentication.getUser().info.premium) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe o seu estado"
						];
						return;
					}
					if (!this.user.city && !authentication.getUser().info.premium) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe a sua cidade"
						];
						return;
					}
					if (!this.user.neighborhood && !authentication.getUser().info.premium) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe o seu bairro"
						];
						return;
					}
					if (!this.user.address && !authentication.getUser().info.premium) {
						this.warningMessage = this.$translate.locale[
							"Por favor, informe o seu endereço"
						];
						return;
					}
				}				

				this.warningMessage = "";
				this.loading = true;

				http
					.put("user", this.user, authentication.getHeaders())
					.then((response) => {
						if (response.status === 200) {
							this.updateSuccess = true;
							console.log(response.data);
							let objUser = JSON.parse(localStorage.user);
							objUser.info = response.data;
							localStorage.user = JSON.stringify(objUser);

							setTimeout(() => {
								this.updateSuccess = false;
							}, 3500);
						}
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
