<template>
	<div>
		<transition enter-active-class="animated fadeIn">
			<div v-show="showEmail" class="email-holder">
				<div class="email-wrapper">
					<h1 class="title">{{ t("Enviar template") }}</h1>
					<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
						<p class="text-desc text-brown text-bold">
							{{ t("Destinatário") }} *
						</p>
						<input
							v-model="email.email"
							type="text"
							:placeholder="t('nome@dominio.com')"
						/>
					</div>

					<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
						<p class="text-desc text-brown text-bold">{{ t("Assunto") }} *</p>
						<input v-model="email.subject" type="text" placeholder="..." />
					</div>

					<transition
						enter-active-class="animated fadeInDown"
						leave-active-class="animated fadeOutDown"
					>
						<p v-show="emailSent" class="text-desc text-center text-blue mt-2">
							{{ t("E-mail enviado com sucesso.") }}
						</p>
					</transition>

					<transition enter-active-class="animated fadeIn">
						<p
							v-show="warningMessage != ''"
							class="text-desc text-center text-red mt-2"
						>
							{{ warningMessage }}
						</p>
					</transition>

					<div class="flex-between mt-2 email-btn-holder">
						<button @click="sendTemplateEmail()" class="default bg-blue">
							<div v-show="loading" class="loading-spinner"></div>
							<img
								v-show="!loading"
								src="../assets/images/icon-email.png"
								alt
							/>
							<span v-show="!loading">{{ t("Enviar") }}</span>
						</button>
						<button @click="showEmail = false" class="default">
							<span>{{ t("Cancelar") }}</span>
						</button>
					</div>
				</div>
			</div>
		</transition>

		<transition
			enter-active-class="animated fadeIn"
			leave-active-class="animated fadeOut"
		>
			<div
				v-show="showBrowserShareOptions"
				class="browser-share-holder zindex10000"
			>
				<div class="browser-share-wrapper">
					<span @click="close()" class="close">X</span>
					<h1 class="title">{{ t("Compartilhar via") }}:</h1>
					<div class="browser-share-options-holder">
						<div
							@click="browserShareEmail()"
							class="browser-share-button email"
						>
							<img src="../assets/images/icon-email.png" alt />
							<p>{{ t("E-mail") }}</p>
						</div>
						<!-- <a
              :href="
                `https://web.whatsapp.com/send?text=${this.templateSavedPath}`
              "
              data-action="share/whatsapp/share"
              target="_blank"
              class="browser-share-button whatsapp"
            >
              <img src="../assets/images/icon-whatsapp.png" alt="" />
              <p>Whatsapp</p>
            </a>-->
						<div
							@click="browserShareFacebook()"
							class="browser-share-button facebook"
						>
							<img src="../assets/images/icon-facebook.png" alt />
							<p>Facebook</p>
						</div>
						<!-- <div
              @click="browserShareTwitter()"
              class="browser-share-button twitter"
            >
              <img src="../assets/images/icon-twitter.png" alt="" />
              <p>Twitter</p>
            </div>-->
					</div>
					<!-- <p
            class="text-desc text-bold mt-2"
          >Baixe nosso aplicativo para uma experiência mais completa:</p>
          <div class="browser-share-options-holder">
            <a class="browser-share-button" href>
              <img src="../assets/images/icon-apple.png" alt />
              <p>iOS</p>
            </a>
            <a class="browser-share-button" href>
              <img src="../assets/images/icon-android.png" alt />
              <p>Android</p>
            </a>
          </div> -->
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	export default {
		name: "Share",
		props: {
			image: "",
			shareIt: false,
		},
		data() {
			return {
				showBrowserShareOptions: false,
				showEmail: false,
				emailSent: false,
				email: {
					email: "",
					subject: "",
					image: "",
				},
				warningMessage: "",
				loading: false,
			};
		},
		created() {
			try {
				this.devicePlatform = device.platform.toLowerCase();
			} catch (e) {
				this.devicePlatform = "browser";
			}
		},
		methods: {
			sendTemplateEmail() {
				if (!this.email.email) {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe o destinatário."
					];
					return;
				}
				if (!this.email.subject) {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe o assunto."
					];
					return;
				}
				this.warningMessage = "";
				this.loading = true;
				this.email.image = this.image;
				http
					.post(
						"userTemplate/send/email",
						this.email,
						authentication.getHeaders()
					)
					.then((response) => {
						this.emailSent = true;
						this.email.email = "";
						this.email.subject = "";
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			browserShareEmail() {
				this.showEmail = true;
			},
			browserShareFacebook() {
				var img = this.image;
				var txt = "@APP.DOIT";
				window.open(
					"https://www.facebook.com/sharer.php?u=" +
						encodeURIComponent(img) +
						"&t=" +
						encodeURIComponent(txt),
					"sharer",
					"toolbar=0,status=0,width=500,height=500"
				);
				return false;
			},
			shareSuccess(result) {
				//console.log(result);
				this.$emit("shareImageReceived");
			},
			shareError(msg) {
				//console.log(msg);
				this.$emit("shareImageReceived");
			},
			share(imageUrl) {
				if (this.devicePlatform == "android" || this.devicePlatform == "ios") {
					var vm = this; // vuejs reference
					var options = {
						message: "", // not supported on some apps (Facebook, Instagram)
						subject: "", //  for email
						files: [imageUrl], // an array of filenames either locally or remotely
						// url: "https://rubik.studio",
						// chooserTitle: "Compartilhar via: " // Android only, you can override the default share sheet title
					};

					window.plugins.socialsharing.shareWithOptions(
						options,
						vm.shareSuccess,
						vm.shareError
					);
				} else {
					this.showBrowserShareOptions = true;
					this.$emit("shareImageReceived");
				}
			},
			fnShareIt() {
				if (this.shareIt && this.image) {
					this.share(this.image);
				}
			},
			close() {
				this.showBrowserShareOptions = false;
				this.$emit("close");
			},
		},
		watch: {
			shareIt: "fnShareIt",
		},
	};
</script>
