<template>
	<div>
		<Header></Header>

		<div v-show="buying" class="bg-cover flex-center">
			<div class="desktop12 tablet12 mobile12 flex-center flex-column">
				<div class="loading-spinner"></div>
				<p class="text-desc mt-1 text-white text-center">
					{{ t("Processando pagamento") }}
					<br />{{ t("Por favor, aguarde") }}
				</p>
			</div>
		</div>

		<section class="page-content page-anim2">
			<div class="container pb-2">
				<h1 class="title text-blue my-1 section-title">
					{{ t("Templates Extras") }}
				</h1>
				<p class="text-desc medium mt-1 text-blue">
					{{
						t(
							"Escolha o pacote que melhor lhe atende e não fique sem os templates que você quer!"
						)
					}}
				</p>
				<p class="text-desc medium text-blue desktop7">
					{{
						t(
							"Os download extras de templates adquiridos aqui não possuem prazo de validade. Duram até você usar todos."
						)
					}}
				</p>
				<transition enter-active-class="animated fadeIn">
					<div class="container flex-start packages-holder">
						<div
							v-for="pack in packages"
							:key="pack.id"
							class="plan-card mt-2 pointer desktop12 tablet12 mobile12"
							:class="{
								'shadow-default': selectedPackage.id == pack.id,
							}"
							@click="selectPack(pack)"
						>
							<div class="plan-header flex-between">
								<p class="text-desc text-white text-bold">{{ pack.name }}</p>
								<p class="text-desc text-brown-light text-bold">
									{{ currency(pack.value) }}
								</p>
							</div>
							<p class="text-desc text-light description text-brown">
								{{ pack.quantity }} <span>{{ t("templates") }}</span>
							</p>
						</div>
					</div>
				</transition>
				<div class="container small desktop4 mt-3-desktop">
					<transition enter-active-class="animated fadeIn">
						<p
							v-show="warningMessage != ''"
							class="text-desc text-blue text-center mt-2"
						>
							{{ warningMessage }}
						</p>
					</transition>
				</div>

				<transition enter-active-class="page-anim2">
					<div id="creditCardHolder" v-show="selectedPackage.id">
						<div class="container container500">
							<h1 class="title text-blue mt-3">{{ t("Dados do cartão") }}</h1>
							<div class="container container500 pb-55-mobile">								
								<div
									class="input-holder labeled login first desktop12 tablet12 mobile12"
								>
									<p class="text-desc text-brown text-bold">
										{{ t("Número do cartão") }} *
									</p>
									<img src="../assets/images/icon-card.png" />
									<the-mask
										v-model="creditCard.number"
										:mask="['#### #### #### ####']"
										:masked="false"
									/>
								</div>
								<div class="flex-between">
									<div
										class="input-holder half labeled login first desktop12 tablet12 mobile5"
									>
										<p
											class="text-desc text-brown text-bold"
											style="font-size:1.5em;"
										>
											{{ t("Vencimento") }} *
										</p>
										<img src="../assets/images/icon-calendar.png" />
										<select v-model="creditCard.month">
											<option
												value=""
											>{{t('Mês')}}</option>
											<option
												v-for="month in ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
												:key="month"
												:value="month"
												>{{ month }}</option
											>
										</select>
									</div>
									<div
										class="input-holder half labeled login first desktop12 tablet12 mobile5"
									>
										<p class="text-desc text-brown text-bold"></p>
										<img src="../assets/images/icon-calendar.png" />
										<select v-model="creditCard.year">
											<option
												value=""
											>{{t('Ano')}}</option>
											<option
												v-for="year in creditCardYears"
												:key="year"
												:value="year"
												>{{ year }}</option
											>
										</select>
									</div>
								</div>
								<div
									class="input-holder labeled login first desktop12 tablet12 mobile12"
								>
									<p class="text-desc text-brown text-bold">
										{{ t("Código de segurança") }} *
									</p>
									<img src="../assets/images/icon-lock.png" />
									<input
										v-model="creditCard.securityCode"
										type="number"
										:placeholder="t('Ex:') + ' 123'"
									/>
								</div>
									<div>
										<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
											<p class="text-desc text-brown text-bold">
												{{ t("País") }} *
											</p>
											<img
												class="icon-address"
												src="../assets/images/icon-address.png"
											/>
											<select v-model="user.country" style="padding-left:30px;">
												<option
												v-for="(country, index) in countries"
												:key="index"
												:value="country"
												>{{ country.nativeName }}</option
												>
											</select>
										</div>
										<div
											class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
										>
											<p class="text-desc text-brown text-bold">
												{{ t("Estado ou Província") }} *
											</p>
											<img
												class="icon-address"
												src="../assets/images/icon-address.png"
											/>
											<input
												v-model="user.state"
												type="text"
												:placeholder="t('Ex:') + ' SP'"
											/>
										</div>
										<div
											class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
										>
											<p class="text-desc text-brown text-bold">
												{{ t("Cidade") }} *
											</p>
											<img
												class="icon-address"
												src="../assets/images/icon-address.png"
											/>
											<input v-model="user.city" type="text" />
										</div>
										<div
											class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
										>
											<p class="text-desc text-brown text-bold">
												{{ t("Endereço") }} *
											</p>
											<img
												class="icon-address"
												src="../assets/images/icon-address.png"
											/>
											<input
												v-model="user.address"
												type="text"
												:placeholder="
													t('Ex:') + ' ' + t('Rua') + ' Washington, 123, SP'
												"
											/>
										</div>
									</div>
									<div
										class="input-holder labeled mt-2 desktop12 tablet12 mobile12"
									>
										<p class="text-desc text-brown text-bold">
											{{ t("CEP") }} *
										</p>
										<img
											class="icon-address"
											src="../assets/images/icon-address.png"
										/>
										<input
											v-model="user.zipcode"
											type="text"
											:placeholder="t('Ex:') + ' 12345-678'"
										/>
									</div>

								<transition enter-active-class="animated fadeIn">
									<p
										v-show="warningMessage != ''"
										class="text-desc text-blue text-center mt-2"
									>
										{{ warningMessage }}
									</p>
								</transition>

								<button @click="buy()" class="default bg-brown mt-2 mb-2">
									<span v-show="!loading">{{ t("Comprar Pacote") }}</span>
									<div v-show="loading" class="loading-spinner"></div>
								</button>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</section>
	</div>
</template>
<script>
	import http from "@/http";
	import Header from "../components/Header";
	import authentication from "../util/authentication";
	import Countries from "@/assets/countries.json";

	export default {
		name: "PacotesInternacionais",
		components: {
			Header,
		},
		userInitialData() {
			return {};
		},
		data() {
			return {
				step: 1,
				loading: true,
				warningMessage: "",
				packages: [],
				selectedPackage: {
					id: null,
				},
				buying: false,
				creditCardYears: [],
				creditCard: {
					number: "",
					month: "",
					year: "",
					securityCode: "",
				},
				user: {
					address: "",
					zipcode: "",
					city: "",
					state: "",
					country: "",
				},
			};
		},
		created() {
			this.countries = Countries;
			var currentYear = new Date().getFullYear();
			for(let i = currentYear; i < currentYear + 10; i++) {
				this.creditCardYears.push(i.toString());
			}

			http
				.get("transaction/plan?paymentMethod=Stripe")
				.then((response) => {
					this.packages = response.data;
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		methods: {
			currency(value) {
				return "$ " + value.toFixed(2);
			},
			selectPack(pack) {
				this.selectedPackage = pack;
				setTimeout(() => {
					const distance =
						document.querySelector("#creditCardHolder").offsetTop - 10;
					window.scroll({
						top: distance,
						left: 0,
						behavior: "smooth",
					});
				}, 150);
			},
			buy() {
				var authUser = authentication.getUser().info;
				var transactionForm = {
					transaction: {
						transactionPlanId: this.selectedPackage.id,
						creditCard: {
							number: this.creditCard.number,
							expMonth: this.creditCard.month,
							expYear: this.creditCard.year,
							cvc: this.creditCard.securityCode,
						}
					},
					user: {
						id: authUser.id,
						email: authUser.email,
						name: authUser.name,
						phone: authUser.phone,
						city: this.user.city,
						country: this.user.country.alpha2Code,
						address: this.user.address,
						zipcode: this.user.zipcode,
						state: this.user.state,
					},
				};
			    this.buying = true;
				this.loading = true;
				http
					.post(
						"transaction",
						transactionForm,
						authentication.getHeaders()
					)
					.then(() => {
						this.$swal.fire({
							type: "success",
							title: this.$translate.locale["Pedido recebido"],
							text: this.$translate.locale[
								"Seu pagamento está sendo processado. Isso pode levar até 48 horas"
							],
						});
						this.selectedPackage.id = null;
						this.$router.push("/home");
					})
					.catch((err) => {
						console.log(err);
						this.$swal.fire({
							type: "error",
							title: this.$translate.locale["Algo deu errado"],
							text: this.$translate.locale[
								"Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente"
							],
						});
					})
					.finally(() => {
						this.buying = false;
						this.loading = false;
					});
			},
		},
	};
</script>
<style scoped>
	.description {
		font-family: fontBold;
		text-align: center;
		font-size: 7.4em;
		color: #0f2651 !important;
		margin: 7px auto 0 auto !important;
	}

	.description span {
		position: relative;
		display: block;
		font-family: fontRegular;
		font-size: 0.25em;
		color: #ba8368;
		margin-top: -15px !important;
	}

	.medium {
		font-family: fontRegular;
		font-size: 1.6em !important;
	}

	.packages-holder .plan-card:nth-child(2n + 2) {
		margin-left: 2.3333%;
		margin-right: 2.3333%;
	}

	.input-holder.half {
		width: 48% !important;
	}
</style>
