<template>
  <div>
    <Header></Header>

    <div v-show="buying" class="bg-cover flex-center">
      <div class="desktop12 tablet12 mobile12 flex-center flex-column">
        <div class="loading-spinner"></div>
        <p class="text-desc mt-1 text-white text-center">
          {{ t("Processando pagamento") }}
          <br />{{ t("Por favor, aguarde") }}
        </p>
      </div>
    </div>

    <section class="page-content page-anim2">
      <div class="container pb-2">
        <h1 class="title text-blue my-1 section-title">
          {{ t("Templates Extras") }}
        </h1>
        <p class="text-desc medium mt-1 text-blue">
          {{
            t(
              "Escolha o pacote que melhor lhe atende e não fique sem os templates que você quer!"
            )
          }}
        </p>
        <p class="text-desc medium text-blue desktop7">
          {{
            t(
              "Os download extras de templates adquiridos aqui não possuem prazo de validade. Duram até você usar todos."
            )
          }}
        </p>
        <transition enter-active-class="animated fadeIn">
          <div class="container flex-start packages-holder">
            <div
              v-for="pack in packages"
              :key="pack.id"
              class="plan-card mt-2 pointer desktop12 tablet12 mobile12"
              :class="{
                'shadow-default': selectedPackage.id == pack.id,
              }"
              @click="selectPack(pack)"
            >
              <div class="plan-header flex-between">
                <p class="text-desc text-white text-bold">{{ pack.name }}</p>
                <p class="text-desc text-brown-light text-bold">
                  {{ pack.value | currency }}
                </p>
              </div>
              <p class="text-desc text-light description text-brown">
                {{ pack.quantity }} <span>{{ t("templates") }}</span>
              </p>
            </div>
          </div>
        </transition>
        <div class="container small desktop4 mt-3-desktop">
          <transition enter-active-class="animated fadeIn">
            <p
              v-show="warningMessage != ''"
              class="text-desc text-blue text-center mt-2"
            >
              {{ warningMessage }}
            </p>
          </transition>
        </div>

        <transition enter-active-class="page-anim2">
          <div id="creditCardHolder" v-show="selectedPackage.id">
            <div class="container container500">
              <h1 class="title text-blue mt-3">{{ t("Dados do cartão") }}</h1>
              <div class="cb-holder mt-2">
                <input
                  @change="setUserDataForCard()"
                  v-model="useUserData"
                  type="checkbox"
                  id="useMyInfo"
                />
                <label for="useMyInfo" class="text-desc text-brown">
                  {{
                    t(
                      "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone, pode manter o seu)"
                    )
                  }}
                </label>
              </div>
              <div class="container container500 pb-55-mobile">
                <div v-show="!useUserData">
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("CPF") }} *
                    </p>
                    <img class="icon-cpf" src="../assets/images/icon-cpf.png" />
                    <the-mask
                      v-model="user.cpf"
                      :mask="['###.###.###-##', '##.###.###/####-##']"
                      :masked="false"
                      :placeholder="t('Ex:') + ' 123.456.789-10'"
                    />
                  </div>
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("E-mail") }} *
                    </p>
                    <img class="icon-pwd" src="../assets/images/icon-pwd.png" />
                    <input
                      v-model="user.email"
                      type="email"
                      :placeholder="t('Ex:') + ' ' + t('nome@dominio.com')"
                    />
                  </div>
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("Data de nascimento") }} *
                    </p>
                    <img src="../assets/images/icon-birthday.png" />
                    <the-mask
                      v-model="auxBirthday"
                      :placeholder="t('DD/MM/AAAA')"
                      :mask="['##/##/####']"
                      :masked="true"
                    />
                  </div>
                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("Telefone") }} *
                    </p>
                    <img src="../assets/images/icon-phone.png" />
                    <the-mask
                      v-model="user.phone"
                      :mask="['(##) ####-####', '(##) #####-####']"
                      :masked="false"
                      :placeholder="t('Ex:') + ' (11) 1234-5678'"
                    />
                  </div>

                  <div
                    class="
                      input-holder
                      labeled
                      mt-2
                      desktop12
                      tablet12
                      mobile12
                    "
                  >
                    <p class="text-desc text-brown text-bold">
                      {{ t("CEP") }} *
                    </p>
                    <img
                      class="icon-address"
                      src="../assets/images/icon-address.png"
                    />
                    <the-mask
                      @blur.native="getCep()"
                      v-model="user.zipcode"
                      :mask="['#####-###']"
                      :masked="false"
                      :placeholder="t('Ex:') + ' 12345-678'"
                    />
                  </div>

                  <transition enter-active-class="animated fadeIn">
                    <div v-show="user.zipcode.length > 7">
                      <div
                        class="
                          input-holder
                          labeled
                          mt-2
                          desktop12
                          tablet12
                          mobile12
                        "
                      >
                        <p class="text-desc text-brown text-bold">
                          {{ t("UF") }} *
                        </p>
                        <img
                          class="icon-address"
                          src="../assets/images/icon-address.png"
                        />
                        <input
                          v-model="user.state"
                          type="text"
                          :placeholder="t('Ex:') + ' SP'"
                        />
                      </div>
                      <div
                        class="
                          input-holder
                          labeled
                          mt-2
                          desktop12
                          tablet12
                          mobile12
                        "
                      >
                        <p class="text-desc text-brown text-bold">
                          {{ t("Cidade") }} *
                        </p>
                        <img
                          class="icon-address"
                          src="../assets/images/icon-address.png"
                        />
                        <input v-model="user.city" type="text" />
                      </div>
                      <div
                        class="
                          input-holder
                          labeled
                          mt-2
                          desktop12
                          tablet12
                          mobile12
                        "
                      >
                        <p class="text-desc text-brown text-bold">
                          {{ t("Bairro") }} *
                        </p>
                        <img
                          class="icon-address"
                          src="../assets/images/icon-address.png"
                        />
                        <input v-model="user.neighborhood" type="text" />
                      </div>
                      <div
                        class="
                          input-holder
                          labeled
                          mt-2
                          desktop12
                          tablet12
                          mobile12
                        "
                      >
                        <p class="text-desc text-brown text-bold">
                          {{ t("Endereço") }} *
                        </p>
                        <img
                          class="icon-address"
                          src="../assets/images/icon-address.png"
                        />
                        <input
                          v-model="user.address"
                          type="text"
                          :placeholder="
                            t('Ex:') + ' ' + t('Rua') + ' Washington, 123, SP'
                          "
                        />
                      </div>
                      <div
                        class="
                          input-holder
                          labeled
                          mt-2
                          desktop12
                          tablet12
                          mobile12
                        "
                      >
                        <p class="text-desc text-brown text-bold">
                          {{ t("Número da residência") }} *
                        </p>
                        <img
                          class="icon-address"
                          src="../assets/images/icon-address.png"
                        />
                        <input v-model="user.number" type="text" placeholder />
                      </div>
                    </div>
                  </transition>
                </div>

                <div
                  class="
                    input-holder
                    labeled
                    login
                    first
                    desktop12
                    tablet12
                    mobile12
                  "
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("Nome impresso no cartão") }} *
                  </p>
                  <img src="../assets/images/icon-card.png" />
                  <input v-model="creditCard.name" type="text" />
                </div>
                <div
                  class="
                    input-holder
                    labeled
                    login
                    first
                    desktop12
                    tablet12
                    mobile12
                  "
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("Número do cartão") }} *
                  </p>
                  <img src="../assets/images/icon-card.png" />
                  <the-mask
                    @input="checkCardFlag()"
                    v-model="creditCard.number"
                    :mask="['#### #### #### ####']"
                    :masked="false"
                  />
                </div>
                <div
                  class="
                    input-holder
                    labeled
                    login
                    first
                    desktop12
                    tablet12
                    mobile12
                  "
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("Bandeira do cartão") }} *
                  </p>
                  <img src="../assets/images/icon-card.png" />
                  <select
                    @change="setCreditCardFlagImage"
                    v-model="creditCard.flag"
                  >
                    <option value="mastercard">Mastercard</option>
                    <option value="visa">Visa</option>
                    <option value="amex">American Express</option>
                    <option value="diners">Diners</option>
                    <option value="elo">Elo</option>
                    <option value="hipercard">Hipercard</option>
                  </select>
                </div>
                <div class="flex-between">
                  <div
                    class="
                      input-holder
                      half
                      labeled
                      login
                      first
                      desktop12
                      tablet12
                      mobile5
                    "
                  >
                    <p
                      class="text-desc text-brown text-bold"
                      style="font-size: 1.5em"
                    >
                      {{ t("vencimento") }} *
                    </p>
                    <img src="../assets/images/icon-calendar.png" />
                    <the-mask
                      v-model="creditCard.month"
                      :placeholder="t('Mês')"
                      :mask="['##']"
                      :masked="false"
                    />
                  </div>
                  <div
                    class="
                      input-holder
                      half
                      labeled
                      login
                      first
                      desktop12
                      tablet12
                      mobile5
                    "
                  >
                    <p class="text-desc text-brown text-bold"></p>
                    <img src="../assets/images/icon-calendar.png" />
                    <the-mask
                      v-model="creditCard.year"
                      :placeholder="t('Ano') + ': ' + t('AAAA')"
                      :mask="['####']"
                      :masked="false"
                    />
                  </div>
                </div>
                <div
                  class="
                    input-holder
                    labeled
                    login
                    first
                    desktop12
                    tablet12
                    mobile12
                  "
                >
                  <p class="text-desc text-brown text-bold">
                    {{ t("Código de segurança") }} *
                  </p>
                  <img src="../assets/images/icon-lock.png" />
                  <input
                    v-model="creditCard.securityCode"
                    type="number"
                    :placeholder="t('Ex:') + ' 123'"
                  />
                </div>

                <transition enter-active-class="animated zoomIn">
                  <div
                    v-show="creditCard.name && creditCard.number"
                    class="creditcard my-2"
                  >
                    <img class="chip" src="../assets/images/icon-cpu.png" alt />
                    <p class="subtitle number text-white">
                      {{ creditCard.number }}
                    </p>
                    <p
                      style="text-transform: uppercase"
                      class="subtitle name text-white"
                    >
                      {{ creditCard.name }}
                    </p>
                    <div class="dates flex-start">
                      <p class="text-desc text-white">
                        {{ t("Validade") }}:
                        <b>{{
                          ` ${this.creditCard.month}/${this.creditCard.year}`
                        }}</b>
                      </p>
                    </div>
                    <transition
                      enter-active-class="animated zoomIn"
                      leave-active-class="animated zoomOut"
                    >
                      <img
                        v-show="creditCardFlagImage"
                        :src="creditCardFlagImage"
                        class="flag"
                      />
                    </transition>
                    <p class="text-desc security-code text-white">
                      {{ creditCard.securityCode }}
                    </p>
                  </div>
                </transition>

                <transition enter-active-class="animated fadeIn">
                  <p
                    v-show="warningMessage != ''"
                    class="text-desc text-blue text-center mt-2"
                  >
                    {{ warningMessage }}
                  </p>
                </transition>

                <button @click="buy()" class="default bg-brown mt-2 mb-2">
                  <span v-show="!loading">{{ t("Comprar Pacote") }}</span>
                  <div v-show="loading" class="loading-spinner"></div>
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import authentication from "../util/authentication";
import validators from "../util/validators";
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from "credit-card-type";

export default {
  name: "Pacotes",
  components: {
    Header,
  },
  userInitialData() {
    return {};
  },
  data() {
    return {
      step: 1,
      loading: true,
      warningMessage: "",
      packages: [],
      selectedPackage: {
        id: null,
      },
      creditCard: {
        number: "",
        name: "",
        month: "",
        year: "",
        securityCode: "",
        flag: "",
      },
      user: {
        name: "",
        email: "",
        phone: "",
        cpf: "",
        birthday: "",
        address: "",
        number: "",
        neighborhood: "",
        zipcode: "",
        city: "",
        state: "",
      },
      auxBirthday: "",
      useUserData: true,
      creditCardFlagImage: "",
      buying: false,
    };
  },
  created() {
    this.setUserDataForCard();
    try {
      if (device.platform.toLowerCase() === "ios") {
        this.$router.push("/");
      }
    } catch (e) {
      console.log("platform error: " + e);
    }
    http
      .get("transaction/plan")
      .then((response) => {
        this.packages = response.data;
        localStorage.a = JSON.stringify(response.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getCep() {
      this.user.zipcode = this.user.zipcode.replace(/[^\d]/g, "");

      if (this.user.zipcode.length != 8) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um cep válido"];
        return;
      } else {
        http
          .get(`https://viacep.com.br/ws/${this.user.zipcode}/json/`)
          .then((response) => {
            this.user.state = response.data.uf;
            this.user.city = response.data.localidade;
            this.user.neighborhood = response.data.bairro;
            this.user.address = response.data.logradouro;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.showFullAddress = true;
          });
      }
    },
    setUserDataForCard() {
      if (this.useUserData) {
        this.user = { ...this.user, ...authentication.getUser().info };
        this.user.birthday = this.$moment(
          authentication.getUser().info.birthday
        ).format("DD/MM/YYYY");
        this.auxBirthday = this.user.birthday;
        console.log(this.user);
      } else {
        this.user = {
          name: "",
          email: "",
          phone: "",
          cpf: "",
          birthday: "",
          address: "",
          number: "",
          neighborhood: "",
          zipcode: "",
          city: "",
          state: "",
        };
      }
    },
    selectPack(pack) {
      this.selectedPackage = pack;
      setTimeout(() => {
        const distance =
          document.querySelector("#creditCardHolder").offsetTop - 10;
        window.scroll({
          top: distance,
          left: 0,
          behavior: "smooth",
        });
      }, 150);
    },
    buy() {
      if (
        !validators.validateCPF(this.user.cpf) &&
        !validators.validateCNPJ(this.user.cpf)
      ) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o cpf do dono o cartão"];
        return;
      }
      if (!validators.validateEmail(this.user.email)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o e-mail do dono do cartão"
          ];
        return;
      }
      let dateRegex = /^\d{2,2}\/\d{2,2}\/\d{4,4}$/;
      if (!dateRegex.test(this.auxBirthday)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe a data de nascimento do dono do cartão"
          ];
        return;
      }
      let phoneRegex = /^[1-9]{2}9?[0-9]{8}$/;
      if (!phoneRegex.test(this.user.phone)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o número de telefone ou celular do dono do cartão"
          ];
        return;
      }
      if (this.user.zipcode == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o cep do dono do cartão"];
        return;
      }
      if (this.user.state == "" || this.user.state.length != 2) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o UF do dono do cartão"];
        return;
      }
      if (this.user.city == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe a cidade do dono do cartão"
          ];
        return;
      }
      if (this.user.neighborhood == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o bairro do dono do cartão"
          ];
        return;
      }
      if (this.user.address == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o endereço do dono do cartão"
          ];
        return;
      }
      if (this.user.number == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o número do dono do cartão"
          ];
        return;
      }
      if (this.creditCard.name.length < 3) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o nome igual está escrito no cartão"
          ];
        return;
      }
      if (!validators.cardFlag(this.creditCard.number)) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o número do cartão"];
        return;
      }
      if (this.creditCard.flag == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe a bandeira do cartão"];
        return;
      }
      if (this.creditCard.month == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o mês de validade do cartão"
          ];
        return;
      }
      if (this.creditCard.year.length !== 4) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o ano de validade do cartão no formato AAAA"
          ];
        return;
      }
      if (this.creditCard.securityCode == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe o código de segurança do cartão"
          ];
        return;
      }
      this.warningMessage = "";
      this.user.name = this.creditCard.name;

      let splitDate = this.auxBirthday.split("/");
      this.user.birthday = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

      var vm = this;
      var cc = this.creditCard;
      vm.buying = true;

      consulta.getPaymentToken(
        {
          brand: cc.flag, // bandeira do cartão
          number: cc.number, // número do cartão
          cvv: cc.securityCode, // código de segurança
          expiration_month: cc.month, // mês de vencimento
          expiration_year: cc.year, // ano de vencimento
        },
        function (error, response) {
          if (error) {
            console.log(error);
            vm.$swal.fire({
              type: "error",
              title: vm.$translate.locale["Algo deu errado"],
              text: vm.$translate.locale[
                "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente."
              ],
            });
            vm.buying = false;
            vm.loading = false;
            http.post("message/error", {
              page: "Pacotes: GetPaymentToken",
              message: error,
            });
          } else {
            var payment_token = response.data.payment_token;

            var transactionInfo = {
              transactionPlanId: vm.selectedPackage.id,
              paymentToken: payment_token,
            };
            var transactionForm = {
              transaction: transactionInfo,
              user: vm.user,
            };

            http
              .post("transaction", transactionForm, authentication.getHeaders())
              .then(() => {
                vm.$swal.fire({
                  type: "success",
                  title: vm.$translate.locale["Pedido recebido"],
                  text: vm.$translate.locale[
                    "Seu pagamento está sendo processado. Isso pode levar até 48 horas"
                  ],
                });
                vm.selectedPackage.id = null;
              })
              .catch((err) => {
                console.log(err);
                vm.$swal.fire({
                  type: "error",
                  title: vm.$translate.locale["Algo deu errado"],
                  text: vm.$translate.locale[
                    "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente"
                  ],
                });
              })
              .finally(() => {
                vm.buying = false;
                vm.loading = false;
              });
          }
        }
      );
    },
    setCreditCardFlagImage() {
      if (this.creditCard.flag == "mastercard") {
        this.creditCardFlagImage = require("../assets/images/mastercard.png");
      } else if (this.creditCard.flag == "visa") {
        this.creditCardFlagImage = require("../assets/images/visa.png");
      } else if (this.creditCard.flag == "amex") {
        this.creditCardFlagImage = require("../assets/images/amex.jpg");
      } else if (this.creditCard.flag == "diners") {
        this.creditCardFlagImage = require("../assets/images/diners.png");
      } else if (this.creditCard.flag == "elo") {
        this.creditCardFlagImage = require("../assets/images/elo.png");
      } else if (this.creditCard.flag == "hipercard") {
        this.creditCardFlagImage = require("../assets/images/hipercard.png");
      } else {
        this.creditCardFlagImage = "";
      }

      console.log("Bandeira do cartão: " + this.creditCard.flag);
    },
    checkCardFlag() {
      let flag =
        creditCardType(this.creditCard.number) &&
        creditCardType(this.creditCard.number)[0]
          ? creditCardType(this.creditCard.number)[0].type
          : false;
      if (flag) {
        if (flag === "american-express") flag = "amex";
        if (flag === "diners-club") flag = "diners";
        this.creditCard.flag = flag;
      }

      this.setCreditCardFlagImage();
    },
  },
};
</script>
<style scoped>
.description {
  font-family: fontBold;
  text-align: center;
  font-size: 7.4em;
  color: #0f2651 !important;
  margin: 7px auto 0 auto !important;
}

.description span {
  position: relative;
  display: block;
  font-family: fontRegular;
  font-size: 0.25em;
  color: #ba8368;
  margin-top: -15px !important;
}

.medium {
  font-family: fontRegular;
  font-size: 1.6em !important;
}

.packages-holder .plan-card:nth-child(2n + 2) {
  margin-left: 2.3333%;
  margin-right: 2.3333%;
}

.input-holder.half {
  width: 48% !important;
}
</style>
