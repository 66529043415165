<template>
	<div>
		<div class="container container500">
			<div class="mb-2-desktop"></div>
			<h1 class="title my-1">{{ t("Alterar senha") }}</h1>
			<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
				<p class="text-desc text-brown text-bold">{{ t("Senha Atual") }} *</p>
				<img class="icon-address" src="../assets/images/icon-pwd.png" />
				<input v-model="password.inUse" type="password" placeholder="*******" />
			</div>

			<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
				<p class="text-desc text-brown text-bold">{{ t("Nova Senha") }} *</p>
				<img class="icon-address" src="../assets/images/icon-pwd.png" />
				<input v-model="password.new1" type="password" placeholder="*******" />
			</div>

			<div class="input-holder labeled mt-2 desktop12 tablet12 mobile12">
				<p class="text-desc text-brown text-bold">
					{{ t("Confirmar Nova Senha") }} *
				</p>
				<img class="icon-address" src="../assets/images/icon-pwd.png" />
				<input v-model="password.new2" type="password" placeholder="*******" />
			</div>

			<transition
				enter-active-class="animated fadeInDown"
				leave-active-class="animated fadeOutDown"
			>
				<p v-show="updateSuccess" class="text-desc text-center text-blue mt-2">
					{{ t("Sua senha foi alterada com sucesso.") }}
				</p>
			</transition>

			<transition enter-active-class="animated fadeIn">
				<p
					v-show="warningMessage != ''"
					class="text-desc text-center text-blue mt-2"
				>
					{{ warningMessage }}
				</p>
			</transition>

			<button @click="updatePassword()" class="default bg-brown mt-2 mb-2">
				<span v-show="!loading">{{ t("Alterar Senha") }}</span>
				<div v-show="loading" class="loading-spinner"></div>
			</button>
		</div>
	</div>
</template>

<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	export default {
		name: "Password",
		data() {
			return {
				password: {
					inUse: "",
					new1: "",
					new2: "",
				},
				warningMessage: "",
				updateSuccess: false,
				loading: false,
			};
		},
		methods: {
			updatePassword() {
				if (this.password.inUse == "") {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe a sua senha atual"
					];
					return;
				}
				if (this.password.new1 == "") {
					this.warningMessage = this.$translate.locale[
						"Por favor, informe a sua senha nova"
					];
					return;
				}
				if (this.password.new2 == "") {
					this.warningMessage = this.$translate.locale[
						"Por favor, confirme a sua senha nova"
					];
					return;
				}
				if (this.password.new1 !== this.password.new2) {
					this.warningMessage = this.$translate.locale[
						"Sua nova senha está diferente da confirmação de senha."
					];
					return;
				}

				this.warningMessage = "";
				this.loading = true;

				http
					.put(
						"user/changePassword",
						{
							password: this.password.inUse,
							newPassword: this.password.new1,
						},
						authentication.getHeaders()
					)
					.then((response) => {
						this.updateSuccess = true;
						this.password = {
							inUse: "",
							new1: "",
							new2: "",
						};
					})
					.catch((err) => {
						console.error(err);
						this.warningMessage = err.response.data;
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
