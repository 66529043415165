<template>
  <div>
    <div class="container pb-55-mobile container500">
      <div class="mb-2-desktop"></div>
      <h1 class="title my-1">{{ t("Detalhes do plano") }}</h1>

      <div v-show="loading" class="flex-center">
        <div class="loading-spinner blue my-2"></div>
      </div>

      <div
        v-show="subscription.value && !loading"
        class="plan-item flex-between mt-2"
      >
        <p class="text-desc">
          {{ t("Assinatura") }} <b>{{ subscription.name }}</b>
        </p>
        <p v-show="!loading" class="text-desc text-bold">
          {{ currency(subscription.value / 100) }} / {{ t("mês") }}
        </p>
      </div>

      <div v-show="subscription.value" class="plan-item flex-between mt-1">
        <p v-show="!loading" class="text-desc">Status</p>
        <p v-show="!loading" class="text-desc text-bold">
          {{ subscriptionStatus }}
        </p>
      </div>

      <div
        v-show="
          planDownloads !== null &&
            planDownloads !== undefined &&
            paidOut &&
            !isPremium
        "
        class="plan-item flex-between mt-1"
      >
        <p v-show="!loading" class="text-desc">Downloads {{ t("do plano") }}</p>
        <p v-show="!loading" class="text-desc text-bold">
          {{ `${planDownloads}/${templatePlan}` }}
        </p>
      </div>

      <div
        v-show="templateBalance && !isPremium"
        class="plan-item flex-between mt-1"
      >
        <p class="text-desc">Downloads {{ t("Avulsos") }}</p>
        <p v-show="!loading" class="text-desc text-bold">
          {{ `restam ${templateBalance}` }}
        </p>
      </div>

      <div
        v-show="canUseUntilDate && subscription.value && subscription.active"
        class="plan-item flex-between mt-1"
      >
        <p class="text-desc">{{ t("Renovação em") }}</p>
        <p v-show="!loading" class="text-desc text-bold">
          {{ canUseUntilDate }}
        </p>
      </div>

      <p class="text-desc text-bold" v-if="isPremium">
        {{ t("Usuário") }} Premium
      </p>

			<a :href="linkToBuySingleTemplates" v-show="showBuySingleTemplates">
				<button class="default options bg-blue my-2">
					<span>{{ t("Obter mais downloads de templates") }}</span>
				</button>
			</a>

      <p v-show="!hasToRenewPlan && subscription.active" class="text-desc mt-2">
        {{
          t(
            "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual."
          )
        }}
        <br />{{
          t(
            "Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão."
          )
        }}
        <br />
        <br />{{
          t(
            "Caso você esteja utilizando algum cupom, no cancelamento da assinatura ele também será cancelado, mas você poderá utilizar um novo cupom - caso tenha acesso a algum que esteja vigente na data de escolha do novo plano."
          )
        }}
      </p>

      <p v-show="hasToRenewPlan && !isPremium" class="text-desc mt-2">
        {{
          t(
            "Ative a sua assinatura para ter acesso a todas as funcionalidades do aplicativo."
          )
        }}
      </p>

      <p
        v-show="!subscription.active && canUseUntilDate && !isPremium"
        class="text-desc"
      >
        {{
          `${t(
            "Você pode utilizar o aplicativo normalmente até"
          )} ${canUseUntilDate}. ${t(
            "Após esta data você precisará renovar a sua assinatura"
          )}.`
        }}
      </p>

      <p v-show="!isPremium" class="text-desc mt-2" style="color:#f00;">
        {{
          t(
            "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail"
          )
        }}
        <a
          style="font-size:1em; font-weight:bold;"
          href="mailto:contato@appdoit.com.br"
          >contato@appdoit.com.br</a
        >.
      </p>

      <transition
        enter-active-class="animated fadeInDown"
        leave-active-class="animated fadeOutDown"
      >
        <p
          v-show="planCanceled"
          class="text-desc text-bold text-center text-red mt-2 mb-2"
        >
          {{ t("Seu plano foi cancelado.") }}
        </p>
      </transition>

      <transition enter-active-class="animated fadeIn">
        <p
          v-show="warningMessage != ''"
          class="text-desc text-center text-blue mt-2"
        >
          {{ warningMessage }}
        </p>
      </transition>

      <button
        v-show="!planCanceled && subscription && !hasToRenewPlan"
        @click="cancelSubscription()"
        class="default bg-red mt-2 mb-2"
      >
        <span v-show="!loading">{{ t("Cancelar Assinatura") }}</span>
        <div v-show="loading" class="loading-spinner"></div>
      </button>

      <button
        v-show="showActivePlan"
        @click="renewSubscription()"
        class="default bg-blue mt-2 mb-2"
      >
        <span v-show="!loading">{{ t("Ativar Assinatura") }}</span>
        <div v-show="loading" class="loading-spinner"></div>
      </button>
    </div>
  </div>
</template>

<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	export default {
		name: "Plan",
		data() {
			return {
				loading: true,
				password: {
					inUse: "",
					new1: "",
					new2: "",
				},
				warningMessage: "",
				planCanceled: false,
				subscription: {
					planDetails: {
						downloaded: "",
						templatePlan: "",
					},
				},
				hasToRenewPlan: false,
				canUseUntilDate: "",
				user: {}
			};
		},
		computed: {
			planDownloads() {
				if (this.subscription) {
					return this.subscription.planDetails.downloaded >
						this.subscription.planDetails.templatePlan
						? this.subscription.planDetails.templatePlan
						: this.subscription.planDetails.downloaded;
				} else {
					return null;
				}
			},
			templatePlan() {
				if (this.subscription) {
					return this.subscription.planDetails.templatePlan < 10000
						? this.subscription.planDetails.templatePlan +
								" " +
								this.$translate.locale["neste mês"]
						: this.$translate.locale["Ilimitado"];
				} else {
					return null;
				}
			},
			templateBalance() {
				if (this.subscription) {
					return this.subscription.planDetails.templateBalance;
				} else {
					return null;
				}
			},
			isPremium() {
				return authentication.getUser().info.premium;
			},
			paidOut() {
				if (this.subscription && this.subscription.planDetails) {
					return (
						new Date(this.subscription.planDetails.accessLimit) >= new Date()
					);
				} else {
					return false;
				}
			},
			subscriptionStatus() {
				if(this.subscription.active){
					return this.paidOut ? this.$translate.locale["Pago"] : this.$translate.locale["Aguardando Pagamento"];
				} else {
					return this.$translate.locale["Cancelada"];
				}
			},		
			showActivePlan() {
				var now = new Date();
				var accessLimit = new Date(this.subscription.planDetails.accessLimit);
				return this.hasToRenewPlan && !this.isPremium && accessLimit < now;
			},	
			showBuySingleTemplates() {
				return this.subscription && !this.subscription.active && !this.isPremium;
			},
			linkToBuySingleTemplates() {
				if(this.showBuySingleTemplates) {
					return this.isInternationalUser ?
						'#/pacotes/internacional' :
						'#/pacotes'
				}
				return "#";
			},
			isInternationalUser() {
				return this.user.career.language !== "Portuguese";
			}
		},
		created() {
			this.user = authentication.getUser().info;
			http
				.get("user/subscription", authentication.getHeaders())
				.then((response) => {
					this.subscription = response.data;
					localStorage.x = JSON.stringify(response.data);
					if (this.subscription) {
						if (
							this.subscription &&
							this.subscription.planDetails &&
							this.subscription.planDetails.accessLimit
						) {
							this.canUseUntilDate = this.$moment(
								this.subscription.planDetails.accessLimit
							).format("DD/MM/YYYY");
						}
						if (!this.subscription.active) {
							this.hasToRenewPlan = true;
						}
					} else {
						this.hasToRenewPlan = true;
					}
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		methods: {
			currency(value) {
				if(this.subscription.paymentMethod === "Stripe") {
					return "US$" + value.toFixed(2);
				} else {
					return "R$ " + value.toFixed(2).replace(".", ",");					
				}
			},
			renewSubscription() {
				this.$router.push("pre-cadastro?renew=y");			
			},
			cancelSubscription() {
				this.$swal
					.fire({
						title: this.$translate.locale["CANCELAR ASSINATURA"],
						text: this.$translate.locale[
							"Tem certeza que deseja cancelar a sua assinatura? Esta ação não poderá ser desfeita."
						],
						type: "error",
						showCancelButton: true,
						position: "center",
						allowEnterKey: false,
						cancelButtonText: this.$translate.locale["NÃO"],
						confirmButtonText: this.$translate.locale["SIM"],
						confirmButtonColor: "#e82121",
						cancelButtonColor: "#777",
						customClass: {
							title: "title-class",
							closeButton: "close-button-class",
							content: "text-desc-popup",
						},
					})
					.then((result) => {
						if (result.value) {
							http
								.delete(
									`subscription/${this.subscription.id}`,
									authentication.getHeaders()
								)
								.then(() => {
									this.planCanceled = true;
								})
								.catch((err) => {
									console.log(err);
								});
						}
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
.plan-item {
  position: relative;
  width: 100%;
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed #ccc;
  @media screen and (max-width: 720px) {
    .text-desc:first-child {
      width: 100%;
    }
    .text-desc:last-child {
      margin-top: 5px;
    }
  }
}
</style>
