<template>
	<div class="search-card">
		<h1>{{ t("O que você está buscando?") }}</h1>
		<div class="search-holder">
			<input
				@keypress.enter="search"
				v-model="query"
				type="text"
				:placeholder="t('Buscar') + '...'"
			/>
			<font-awesome-icon @click="search" :icon="['fas', 'search']" />
		</div>
	</div>
</template>
<script>
	export default {
		name: "SearchWidget",
		data() {
			return {
				query: "",
			};
		},
		methods: {
			search() {
				if (!this.query) return;
				this.$router.push(`/busca?q=${this.query}`);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.search-card {
		position: fixed;
		width: 90%;
		max-width: 480px;
		padding: 30px 20px;
		top: 44%;
		left: 0;
		right: 0;
		margin: 0 auto;
		transform: translateY(-50%);
		background-color: #fff;
		border-radius: 8px;
		z-index: 999;
		h1 {
			font-size: 1.8em;
			text-align: center;
			color: var(--blue);
		}
	}

	.search-holder {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 2px solid var(--blue);
		margin: 35px auto 0 auto;
		input {
			border: none;
			width: 94%;
			height: 44px;
			padding: 0 0 0 5px;
			font-size: 1.2em;
			outline: none;
		}
		svg {
			position: relative;
			font-size: 1.4em;
			color: var(--blue);
			cursor: pointer;
		}
	}
</style>
