<template>
  <div class="page-anim1 login-wrapper">
    <div class="container desktop4">
      <img
        src="../assets/images/doit-logo.png"
        alt="Do It"
        class="logo-login"
      />

      <div class="languages-holder">
        <div class="title" v-translate>{{ t("Idiomas:") }}</div>
        <div
          @click="setLanguage('br')"
          class="item"
          :class="{ active: language == 'br' }"
        >
          Português
        </div>
        <div
          @click="setLanguage('en')"
          class="item"
          :class="{ active: language == 'en' }"
        >
          English
        </div>
        <div
          @click="setLanguage('es')"
          class="item"
          :class="{ active: language == 'es' }"
        >
          Español
        </div>
      </div>

      <transition enter-active-class="animated zoomIn">
        <div class="form-holder">
          <p v-translate class="text-desc text-bold text-blue">
            {{ t("Já sou cadastrado") }}
          </p>
          <div class="input-holder login first desktop12 tablet12 mobile12">
            <img src="../assets/images/icon-user.png" />
            <input
              @keypress.enter="login()"
              v-model="loginForm.email"
              type="email"
              placeholder="E-mail"
            />
          </div>

          <div class="input-holder mt-2 desktop12 tablet12 mobile12">
            <img class="icon-pwd" src="../assets/images/icon-pwd.png" />
            <input
              @keypress.enter="login()"
              v-model="loginForm.password"
              type="password"
              :placeholder="t('Senha')"
            />
          </div>

          <transition enter-active-class="animated fadeIn">
            <p
              v-show="warningMessage != ''"
              class="text-desc text-center text-blue mt-2"
              style="position:relative;display:block;"
            >
              {{ warningMessage }}
            </p>
          </transition>

          <transition enter-active-class="animated fadeIn">
            <a
              href="#/recuperar-senha"
              v-show="warningMessage != ''"
              class="text-desc warning-message mt-1"
              style="position:relative;display:block;font-weight:bold;text-align:center;"
            >
              {{ t("Esqueceu sua senha?") }}
            </a>
          </transition>

          <button @click="login()" class="default mt-2">
            <span v-show="!loading">{{ t("Entrar") }}</span>
            <div v-show="loading" class="loading-spinner"></div>
          </button>
        </div>
      </transition>

      <div class="form-holder">
        <p
          class="text-desc text-bold text-blue text-center text-not-registered"
        >
          {{ t("Não sou cadastrado") }}
        </p>
        <router-link to="/pre-cadastro">
          <button class="default bg-blue" v-translate>Cadastrar</button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import validators from "../util/validators";
import authentication from "../util/authentication";

export default {
  name: "Login",
  data() {
    return {
      warningMessage: "",
      loading: false,
      loginForm: {
        email: "",
        password: "",
      },
      language: "",
    };
  },
  created() {
    this.language = localStorage.language || "br";
    localStorage.language = this.language;
    this.$translate.setLang(this.language);
    console.log(this.language);
  },
  methods: {
    setLanguage(lang) {
      this.language = lang;
      localStorage.language = this.language;
      location.reload();
    },
    login() {
      if (!validators.validateEmail(this.loginForm.email)) {
        this.warningMessage = this.$translate.locale[
          "Por favor, verifique se o e-mail digitado está correto."
        ];
        return;
      }
      if (this.loginForm.password == "") {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe sua senha"
        ];
        return;
      }

      this.warningMessage = "";
      this.loading = true;

      authentication
        .login(this.loginForm)
        .then((response) => {
          if (response) {
            this.$router.push("home");
          } else {
            this.warningMessage = this.$translate.locale[
              "Usuário e/ou senha incorreto(s)"
            ];
          }
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage = this.$translate.locale[
            "Algo deu errado. Por favor, tente novamente em alguns instantes."
          ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.languages-holder {
  position: relative;
  width: 100%;
  margin: 25px auto 35px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .title {
    position: relative;
    font-size: 1.8em;
    font-family: fontRegular;
    width: 100%;
    color: var(--blue);
    text-align: center;
  }
  .item {
    position: relative;
    padding: 8px 12px;
    border-radius: 12px;
    font-size: 1.35em;
    text-align: center;
    cursor: pointer;
    transition: color 0.15s ease;
    border: 1px solid transparent;
    &.active,
    &:hover {
      border-color: var(--blue);
      color: var(--blue);
    }
  }
}

.form-holder {
  position: relative;
  width: 80%;
  margin: 25px auto 0 auto;
}

@media only screen and (max-width: 720px) {
  .languages-holder {
    .title {
      font-size: 1.4em;
    }
    img {
      width: 28px;
    }
  }
}
</style>
