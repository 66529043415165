import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter, {
  symbol: "R$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});

import "./assets/css/desktop.css";
import "./assets/css/tablet.css";
import "./assets/css/mobile.css";
import "./assets/css/animate.css";

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import VueTranslate from "vue-translate-plugin";
Vue.use(VueTranslate);

Vue.use(require("vue-moment"));
import VueSweetalert2 from "vue-sweetalert2";
const options = {
  confirmButtonColor: "#0f2651",
  cancelButtonColor: "#aaa",
};
Vue.use(VueSweetalert2, options);
import "sweetalert2/dist/sweetalert2.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faChevronLeft,
  faSearch,
  faBars,
  faHome,
  faCamera,
  faClock,
  faIdBadge,
  faUser,
  faLightbulb,
  faSignOutAlt,
  faEnvelope,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faChevronLeft,
  faChevronRight,
  faFacebook,
  faInstagram,
  faYoutube,
  faSearch,
  faBars,
  faHome,
  faCamera,
  faClock,
  faIdBadge,
  faUser,
  faLightbulb,
  faSignOutAlt,
  faEnvelope,
  faPlus
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
