<template>
	<div>
		<Header></Header>

		<section class="page-content page-anim2">
			<div class="container pb-3 files">
				<div class="flex-start breadcrumb-holder mt-2">
					<p
						@click="exitFolder(item, index)"
						v-for="(item, index) in breadcrumb"
						:key="index"
						class="text-desc text-bold pointer"
					>
						<span class="breadcrumb-separator" v-show="index != 0">-</span>
						{{ item.title }}
					</p>
				</div>

				<div class="files-holder flex-start">
					<div
						@click="enterFolder(folder)"
						v-for="folder in folders"
						:key="folder.id"
						class="file-holder pointer"
					>
						<div class="img-holder">
							<img class="folder" src="../assets/images/icon-folder.png" alt />
						</div>
						<p class="text-desc text-bold text-brown">{{ folder.title }}</p>
					</div>

					<div
						@click="showImageBig(template)"
						v-for="template in templates"
						:key="template.id"
						class="file-holder pointer"
					>
						<div class="img-holder">
							<img :src="template.image" alt="template thumb" />
						</div>
						<p class="text-desc text-bold text-brown">
							{{ template.label }}
						</p>
					</div>
				</div>

				<button
					v-show="!loading && !allTemplatesLoaded"
					@click="loadMoreTemplates()"
					class="default desktop4 mt-2 bg-blue mx-auto"
				>
					<span>{{ t("VER MAIS") }}</span>
				</button>

				<div
					v-show="!loading && templates.length == 0 && folders.length == 0"
					class="flex-center mt-2"
				>
					<p class="text-desc text-center mt-3">
						{{ t("Ops. Nada por aqui.") }}
					</p>
				</div>

				<div v-show="loading" class="flex-center mt-2">
					<div class="loading-spinner blue"></div>
				</div>
			</div>

			<transition
				enter-active-class="animated fadeIn"
				leave-active-class="animated fadeOut"
			>
				<div v-show="showImageDetails">
					<div @click="showImageDetails = false" class="cover-bg"></div>
					<img :src="selectedTemplate.image" alt class="image-big-preview" />
				</div>
			</transition>

			<transition
				enter-active-class="animated fadeIn"
				leave-active-class="animated fadeOut"
			>
				<div class="profile-menu-holder zindex zindex10000">
					<div
						@click="share()"
						v-show="showImageDetails"
						class="profile-menu-item"
					>
						<img
							v-show="!loadingShare"
							src="../assets/images/icon-share.png"
							class="shorter tablet-hidden mobile-hidden"
							alt
						/>
						<img
							v-show="!loadingShare"
							src="../assets/images/icon-share-blue.png"
							class="shorter desktop-hidden"
							alt
						/>
						<p v-show="!loadingShare" class="text-blue">
							{{ t("Compartilhar") }}
						</p>
						<div
							v-show="loadingShare"
							class="loading-spinner blue"
							style="position:relative;top:-19px;"
						></div>
					</div>
					<div
						@click="downloadTemplate()"
						v-show="showImageDetails"
						class="profile-menu-item"
					>
						<img
							v-show="!loadingDownload"
							src="../assets/images/icon-download.png"
							class="shorter"
							alt
						/>
						<p v-show="!loadingDownload" class="text-blue">Download</p>
						<div
							v-show="loadingDownload"
							class="loading-spinner mobile-hidden tablet-hidden"
							style="position:relative;top:-19px;"
						></div>
						<div
							v-show="loadingDownload"
							class="loading-spinner blue desktop-hidden"
							style="position:relative;top:-19px;"
						></div>
					</div>
					<div
						@click="moveTemplate()"
						v-show="showImageDetails"
						class="profile-menu-item"
					>
						<img src="../assets/images/icon-move.png" class="shorter" alt />
						<p class="text-blue">{{ t("Mover") }}</p>
					</div>
					<div
						@click="createFolder"
						v-show="!showImageDetails"
						class="profile-menu-item"
					>
						<img
							src="../assets/images/icon-add-folder.png"
							class="shorter"
							alt
						/>
						<p class="text-blue">{{ t("Criar Pasta") }}</p>
					</div>
					<div
						@click="deleteTemplate()"
						v-show="showImageDetails"
						class="profile-menu-item bg-red"
					>
						<img src="../assets/images/icon-trash.png" class="shorter" alt />
						<p class="text-blue">{{ t("Excluir") }}</p>
					</div>
					<div
						@click="renameFolder()"
						v-show="!showImageDetails && currentFolder.id"
						class="profile-menu-item"
					>
						<img src="../assets/images/icon-rename.png" class="shorter" alt />
						<p class="text-blue">{{ t("Renomear Pasta") }}</p>
					</div>
					<div
						@click="deleteFolder()"
						v-show="!showImageDetails && currentFolder.id"
						class="profile-menu-item"
					>
						<img src="../assets/images/icon-trash.png" class="shorter" alt />
						<p class="text-blue">{{ t("Excluir Pasta") }}</p>
					</div>
				</div>
			</transition>
		</section>

		<Share
			:image="selectedTemplate.image"
			:shareIt="shareMyTemplate"
			@shareImageReceived="shareProcessStarted"
			@close="closeShare"
		></Share>
	</div>
</template>
<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	import Header from "../components/Header";
	import Share from "../components/Share";
	export default {
		name: "MeusTemplates",
		components: {
			Header,
			Share,
		},
		data() {
			return {
				loading: true,
				loadingShare: false,
				loadingDownload: false,
				folders: [],
				breadcrumb: [
					{
						id: null,
						title: "Início",
					},
				],
				currentFolder: {
					id: null,
					parentId: null,
				},
				templates: [],
				selectedTemplate: {},
				showImageDetails: false,
				shareMyTemplate: false,
				page: 1,
				take: 15,
				totalTemplates: null,
				allTemplatesLoaded: false,
			};
		},
		created() {
			this.loadContent();
		},
		methods: {
			share() {
				this.loadingShare = true;
				this.shareMyTemplate = true;
			},
			closeShare() {
				this.shareMyTemplate = false;
			},
			shareProcessStarted() {
				this.shareMyTemplate = false;
				this.loadingShare = false;
			},
			showImageBig(template) {
				this.showImageDetails = true;
				this.selectedTemplate = template;
			},
			loadMoreTemplates() {
				this.page++;
				this.loadContent();
			},
			loadContent() {
				this.loading = true;

				http
					.get(
						`directory?id=${this.currentFolder.id}&page=${this.page}&take=${this.take}`,
						authentication.getHeaders()
					)
					.then((response) => {
						if (response.data.children) {
							this.folders = response.data.children;
						}
						if (this.page === 1) {
							this.totalTemplates = response.data.totalTemplates;
						}

						if (this.page * this.take >= this.totalTemplates) {
							this.allTemplatesLoaded = true;
						}

						this.templates = [...this.templates, ...response.data.userTemplate];
						this.templates.map((temp) => {
							temp.label = this.$moment(temp.timestamp).format("DD/MM/YY");
						});
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			moveTemplate() {
				console.log(this.currentFolder);

				let moveFolders = {};
				if (this.currentFolder.id) {
					moveFolders[this.currentFolder.parentId] = this.$translate.locale[
						"Pasta anterior"
					];
				}

				for (let i = 0; i < this.folders.length; i++) {
					moveFolders[this.folders[i].id] = this.folders[i].title;
				}
				(async () => {
					const { value: chosenFolder } = await this.$swal.fire({
						title: this.$translate.locale["Mover template para:"],
						input: "select",
						inputOptions: moveFolders,
						inputPlaceholder: this.$translate.locale["Selecione uma pasta"],
						showCancelButton: true,
						inputValidator: (value) => {
							return new Promise((resolve) => {
								if (value) {
									resolve();
								} else {
									resolve(
										this.$translate.locale["Você precisa selecionar uma pasta"]
									);
								}
							});
						},
					});

					if (chosenFolder) {
						let folderValue = chosenFolder === "null" ? null : chosenFolder;
						let objTemplate = this.selectedTemplate;
						objTemplate.directoryId = folderValue;
						http
							.put("userTemplate", objTemplate, authentication.getHeaders())
							.then(() => {
								this.templates = this.templates.filter((template) => {
									if (template.id !== objTemplate.id) return template;
								});
								this.showImageDetails = false;
							})
							.catch((err) => {
								console.log(err);
							});
					}
				})();
			},
			deleteTemplate() {
				if (this.selectedTemplate) {
					this.$swal
						.fire({
							title: this.$translate.locale["Deletar Template"],
							text: this.$translate.locale[
								`Tem certeza que deseja excluir este template?`
							],
							type: "error",
							showCancelButton: true,
							position: "center",
							allowEnterKey: false,
							cancelButtonText: this.$translate.locale["CANCELAR"],
							confirmButtonText: this.$translate.locale["SIM"],
							confirmButtonColor: "#e82121",
							cancelButtonColor: "#777",
							customClass: {
								title: "title-class",
								closeButton: "close-button-class",
								content: "text-desc-popup",
							},
						})
						.then((result) => {
							if (result.value) {
								http
									.delete(
										`userTemplate/${this.selectedTemplate.id}`,
										authentication.getHeaders()
									)
									.then(() => {
										this.templates = this.templates.filter((temp) => {
											if (temp.id != this.selectedTemplate.id) return temp;
										});
										this.showImageDetails = false;
									})
									.catch((err) => {
										console.log(err);
									});
							}
						});
				}
			},
			renameFolder() {
				this.$swal.fire({
					title: `${this.$translate.locale["Novo nome para a pasta"]} "${this.currentFolder.title}"`,
					input: "text",
					inputAttributes: {
						autocapitalize: "off",
					},
					showCancelButton: true,
					confirmButtonText: this.$translate.locale["RENOMEAR"],
					cancelButtonText: this.$translate.locale["CANCELAR"],
					showLoaderOnConfirm: true,
					preConfirm: (text) => {
						if (text != "" && text != undefined) {
							this.currentFolder.title = text.replace(/[^a-zA-Z0-9_ ]/g, "");
							http
								.put(
									"directory",
									this.currentFolder,
									authentication.getHeaders()
								)
								.then(() => {
									this.breadcrumb[
										this.breadcrumb.length - 1
									].title = this.currentFolder.title;
								})
								.catch((err) => {
									console.log(err);
								});
						} else {
							this.$swal.showValidationMessage(
								this.$translate.locale["Por favor, informe o nome da pasta"]
							);
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
				});
			},
			deleteFolder() {
				if (this.currentFolder.id) {
					this.$swal
						.fire({
							title: this.$translate.locale["Deletar pasta"],
							text: `${this.$translate.locale["Tem certeza que deseja excluir a pasta"]} "${this.currentFolder.title}" ${this.$translate.locale["e todo o seu conteúdo?"]}`,
							type: "warning",
							showCancelButton: true,
							cancelButtonText: this.$translate.locale["CANCELAR"],
							confirmButtonText: this.$translate.locale["SIM"],
							position: "top",
							allowEnterKey: false,
							confirmButtonColor: "#81be59",
							cancelButtonColor: "#777",
							customClass: {
								title: "title-class",
								closeButton: "close-button-class",
								content: "text-desc-popup",
							},
						})
						.then((result) => {
							if (result.value) {
								http
									.delete(
										`directory/${this.currentFolder.id}`,
										authentication.getHeaders()
									)
									.then(() => {
										this.currentFolder.id = this.currentFolder.parentId;
										this.breadcrumb = this.breadcrumb.slice(
											0,
											this.breadcrumb.length - 1
										);
										this.loadContent();
									})
									.catch((err) => {
										console.log(err);
									});
							}
						});
				}
			},
			createFolder() {
				this.$swal.fire({
					title: this.$translate.locale["Nome da pasta"],
					input: "text",
					inputAttributes: {
						autocapitalize: "off",
					},
					showCancelButton: true,
					confirmButtonText: this.$translate.locale["CRIAR"],
					cancelButtonText: this.$translate.locale["CANCELAR"],
					showLoaderOnConfirm: true,
					preConfirm: (text) => {
						if (text != "" && text != undefined) {
							let directory = {
								parentId: this.currentFolder.id,
								title: text.replace(/[^a-zA-Z0-9_ ]/g, ""),
							};
							http
								.post("directory", directory, authentication.getHeaders())
								.then((response) => {
									this.folders.push(response.data);
								})
								.catch((err) => {
									console.log(err);
								});
						} else {
							this.$swal.showValidationMessage(
								this.$translate.locale["Por favor, informe o nome da pasta"]
							);
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
				});
			},
			exitFolder(folder, index) {
				if (folder.id != this.currentFolder.id) {
					this.breadcrumb = this.breadcrumb.slice(0, index + 1);
					this.currentFolder = folder;
					this.page = 1;
					this.folders = [];
					this.templates = [];
					this.allTemplatesLoaded = false;
					this.loadContent();
				}
			},
			enterFolder(folder) {
				if (folder.id != this.currentFolder.id) {
					this.breadcrumb.push(folder);
					this.currentFolder = folder;
					this.page = 1;
					this.folders = [];
					this.templates = [];
					this.allTemplatesLoaded = false;
					this.loadContent();
				}
			},
			downloadTemplate() {
				this.loadingDownload = true;
				http
					.get(`UserTemplate/${this.selectedTemplate.id}/File`, {
						responseType: "arraybuffer",
						headers: {
							Authorization: "Bearer " + authentication.getUser().accessToken,
						},
					})
					.then((response) => {
						const blob = new Blob([response.data], { type: "image/png" });
						this.downloadBlob(blob);
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loadingDownload = false;
					});
			},
			downloadBlob(blob) {
				var fileName = `${this.selectedTemplate.id}.png`;
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = fileName;
				link.style.opacity = 0;
				link.style.position = "absolute";
				document.body.append(link);
				link.click();
				link.remove();
			},
		},
	};
</script>
