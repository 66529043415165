<template>
	<div>
		<Header :showBackButton="true" />

		<section class="page-content page-anim2">
			<div class="container pb-2">
				<div class="flex-between-desktop">
					<div>
						<h1 class="title text-blue my-1 section-title">
							<span>{{ t("Resultado da busca por") }}:</span> {{ searchQuery }}
						</h1>
					</div>
				</div>

				<div class="cards-home-holder small-children mt-1">
					<div
						v-show="!error"
						v-for="template in templates"
						:key="template.id"
						@click="$router.push(`/template/${template.id}`)"
						class="category-card"
					>
						<div class="img-holder">
							<img class="bg-img" :src="template.coverImage" />
						</div>
						<p class="text-desc card-desc text-brown">{{ template.title }}</p>
					</div>
				</div>

				<div v-show="loading" class="flex-center mt-2">
					<div class="loading-spinner blue"></div>
				</div>

				<div
					v-show="templates.length == 0 && !loading && !error"
					class="flex-center mt-2"
					style="flex-wrap:wrap;"
				>
					<p class="text-desc text-center mt-3 desktop12 tablet12 mobile12">
						{{ t("Nenhum template encontrado.") }}
					</p>
					<router-link to="/" class="back-button">{{
						t("Voltar ao início")
					}}</router-link>
				</div>

				<div class="container pb-2 template-edit-holder">
					<div v-show="error && !loading">
						<p class="text-desc mt-2 text-center">
							{{
								t(
									"Você não possui um plano ativo. Para verificar os detalhes do seu plano, acesse:"
								)
							}}
						</p>
						<a href="#/meusDados?tab=plan">
							<button class="default options bg-blue mt-1">
								<span>{{ t("Meu plano") }}</span>
							</button>
						</a>
					</div>

					<div v-show="error && !loading">
						<p class="text-desc mt-3 text-center">
							{{ t("Para obter downloads agora mesmo:") }}
						</p>
						<a href="#/pacotes">
							<button class="default options bg-blue mt-1">
								<span>{{ t("Obter downloads") }}</span>
							</button>
						</a>
					</div>
				</div>

				<button
					v-show="!allTemplatesFound && !loading && templates.length != 0"
					@click="loadMoreTemplates()"
					class="default"
				>
					<span>{{ t("VER MAIS") }}</span>
				</button>
			</div>
		</section>
	</div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import authentication from "../util/authentication";
export default {
  name: "Busca",
  components: {
    Header,
  },
  data() {
    return {
      error: false,
      loading: true,
      templates: [],
      page: 1,
      take: 15,
      allTemplatesFound: false,
      searchQuery: this.$route.query.q,
    };
  },
  created() {
    this.loadTemplates();
  },
  methods: {
    loadMoreTemplates() {
      this.loading = true;
      this.page = Number(this.page) + 1;
      this.loadTemplates();
    },
    loadTemplates() {
      this.loading = true;
      http
        .get(
          `template?page=${this.page}&take=${this.take}&search=${this.searchQuery}`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.data.error) {
            this.error = true;
          } else {
            this.error = false;
            this.templates = [...this.templates, ...response.data.list];
            if (response.data.list.length < this.take) {
              this.allTemplatesFound = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {},
  },
};
</script>
<style scoped>
	button.default {
		max-width: 340px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.title span {
		position: relative;
		font-family: fontLight;
		font-size: 0.5em;
		color: #ba8368;
		margin-right: 10px;
		top: -5px;
	}

	.back-button {
		position: relative;
		display: block;
		font-size: 1.4em;
		color: var(--blue);
		text-align: center;
		margin-top: 35px;
		text-decoration: underline;
		font-family: "fontBold";
	}
</style>
