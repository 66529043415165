<template>
  <div>
    <Header></Header>

    <div class="fontpreload">
      <p>1</p>
      <p>2</p>
      <p>3</p>
      <p>4</p>
      <p>5</p>
      <p>6</p>
      <p>7</p>
      <p>8</p>
      <p>9</p>
      <p>10</p>
      <p>11</p>
      <p>12</p>
      <p>13</p>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="loading || loadingSubscription" class="bg-cover flex-center">
        <div class="loading-spinner"></div>
      </div>
    </transition>

    <Share
      :image="imageToShare"
      :shareIt="shareMyTemplate"
      @shareImageReceived="shareProcessStarted"
      @close="closeShare"
    ></Share>

    <section class="page-content page-anim2">
      <div class="container pb-2 template-edit-holder">
        <h1 class="title text-blue my-1 section-title">{{ template.title }}</h1>

        <div v-show="!showCanvas && !loadingSubscription">
          <p class="text-desc mt-2 text-center">
            {{
              t(
                "Você atingiu o limite de downloads esse mês ou seu pagamento não foi aprovado. Para verificar os detalhes do seu plano, acesse:"
              )
            }}
          </p>
          <a href="#/meusDados?tab=plan">
            <button class="default options bg-blue mt-1">
              <span>{{ t("Meu plano") }}</span>
            </button>
          </a>
        </div>

        <!-- <div v-show="!showCanvas && !loadingSubscription">
          <p class="text-desc mt-3 text-center">
            {{ t("Para obter mais downloads agora mesmo:") }}
          </p>
          <a href="#/pacotes">
            <button class="default options bg-blue mt-1">
              <span>{{ t("Obter mais downloads") }}</span>
            </button>
          </a>
        </div> -->

        <input
          @change="processFile($event)"
          id="fileInput"
          class="ios-input-file"
          ref="myFiles"
          type="file"
          style="position: fixed; top: -1000px; left: -1000px; opacity: 0;"
        />

        <p v-show="!userSignatureOk" class="text-desc mt-2">
          {{
            t(
              "Você precisa escolher uma foto para a sua assinatura antes de usar um template."
            )
          }}
        </p>

        <a v-show="!userSignatureOk" href="#/assinatura">
          <button class="default options bg-blue mt-2">
            <span>{{ t("Escolher foto") }}</span>
          </button>
        </a>

        <div
          v-show="showCanvas && userSignatureOk"
          id="canvasHolder"
          class="canvas-holder mt-2"
        >
          <canvas id="templateCanvas"></canvas>
        </div>

        <div
          v-show="!loadingSubscription && showCanvas && userSignatureOk"
          class="flex-between mt-2"
        >
          <button @click="save(false)" class="default options bg-blue">
            <img src="../assets/images/icon-save.png" alt />
            <span>{{ t("Salvar") }}</span>
          </button>
          <button @click="save(true)" class="default options">
            <img src="../assets/images/icon-share.png" alt />
            <span>{{ t("Compartilhar") }}</span>
          </button>
        </div>
      </div>
    </section>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showTextInput"
        class="cover-bg"
        style="background: rgba(0, 0, 0, 0.8);"
      >
        <textarea
          v-model="userMessage"
          ref="userTextarea"
          class="input-template-text"
          :placeholder="userPlaceholder"
        ></textarea>
        <div class="container template-text-buttons-holder flex-between mt-2">
          <button
            @click="setUserText()"
            class="default mobile5 tablet4 desktop5 pointer"
            style="background: #3d49e7;"
          >
            {{ t("Confirmar") }}
          </button>
          <button
            @click="cancelUserText()"
            class="default mobile5 tablet4 desktop5 pointer"
            style="background: #d32c2c;"
          >
            {{ t("Cancelar") }}
          </button>
        </div>
      </div>
    </transition>
    <transition leave-active-class="animated fadeOut">
      <ImageCrop
        v-show="selectedImage != ''"
        :imageData="selectedImage"
        :cropSettings="imageCropSettings"
        @photoCropConfirm="photoCropConfirmed"
        @photoCropCancel="photoCropCanceled"
      ></ImageCrop>
    </transition>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import { fabric } from "fabric";
import ImageCrop from "../components/ImageCrop";
import authentication from "../util/authentication";
import Share from "../components/Share";
export default {
  name: "TemplateEdicao",
  components: {
    Header,
    ImageCrop,
    Share,
  },
  data() {
    return {
      imageToShare: "",
      shareMyTemplate: false,
      user: Object,
      userSignatureOk: true,
      loading: true,
      loadingSubscription: true,
      template: {
        title: "",
        description: "",
        canvas: "",
      },
      templateSavedPath: "",
      templateImage: "",
      canvas: Object,
      userPicture: "",
      clickedGroup: "",
      selectedImage: "",
      userMessage: "",
      userPlaceholder: "",
      selectedText: "",
      imageCropSettings: {
        aspectRatio: 1,
        shape: "rect",
      },
      showTextInput: false,
      templateBalance: 0,
      reachedLimit: false,
      validPlan: true,
      windowWidth: Number,
    };
  },
  computed: {
    showCanvas() {
      return this.templateBalance > 0 || (!this.reachedLimit && this.validPlan);
    },
  },
  created() {
    http
      .get("user/details", authentication.getHeaders())
      .then((response) => {
        this.user = response.data;
        if (!this.user.image) this.userSignatureOk = false;

        this.getTemplate();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) this.$router.push("login");
      })
      .finally(() => {
        this.checkUserDownloadLimit();
      });
  },
  mounted() {
    window.addEventListener("resize", this.handleResize, false);
    this.windowWidth = window.innerWidth;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize, false);
  },
  methods: {
    handleResize() {
      if (!this.loading && this.windowWidth !== window.innerWidth) {
        this.loading = true;
        this.windowWidth = window.innerWidth;
        this.canvas.dispose();
        this.$swal
          .fire({
            type: "warning",
            title: this.$translate.locale["Tela redimensionada."],
            text: this.$translate.locale[
              "Ao redimensionar a tela do seu dispositivo você precisa recarregar o template novamente."
            ],
            allowOutsideClick: false,
          })
          .then(() => {
            this.loadCanvas();
          });
      }
    },
    getTemplate() {
      http
        .get(`template/${this.$route.params.id}`, authentication.getHeaders())
        .then((response) => {
          this.template = response.data;
          this.loadCanvas();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 401)
            this.$router.push("/login");
          console.log(err);
        });
    },
    share() {
      this.shareMyTemplate = true;
    },
    closeShare() {
      this.shareMyTemplate = false;
    },
    shareProcessStarted() {
      this.shareMyTemplate = false;
      this.loading = false;
    },
    checkUserDownloadLimit() {
      if (this.user.premium) {
        this.validPlan = true;
        this.loadingSubscription = false;
      } else {
        http
          .get("user/subscription", authentication.getHeaders())
          .then((response) => {
            let subscription = response.data.planDetails;
            if (!subscription || subscription.reachedLimit) {
              this.reachedLimit = true;
            }
            this.templateBalance = subscription
              ? subscription.templateBalance
              : 0;
            if (
              !subscription ||
              !subscription.accessLimit ||
              new Date(subscription.accessLimit) < new Date()
            ) {
              this.validPlan = false;
            }
            localStorage.a = JSON.stringify(subscription);
            console.log("limit reached:", this.reachedLimit);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loadingSubscription = false;
          });
      }
    },
    save(isShare) {
      let resizeFactor = 1080 / this.canvas.width;

      let img = this.canvas.toDataURL({
        format: "image/jpg",
        multiplier: resizeFactor,
      });

      var objTemplate = {
        templateId: this.$route.params.id,
        image: img,
      };

      this.loading = true;
      if (!this.templateSavedPath) {
        // template not saved yet
        http
          .post(
            "userTemplate/template",
            objTemplate,
            authentication.getHeaders()
          )
          .then((response) => {
            this.templateSavedPath = response.data;
            if (isShare) {
              this.imageToShare = response.data;
              this.share();
            } else {
              this.$swal.fire({
                type: "success",
                title: this.$translate.locale[
                  "Template armazenado em 'Meus Templates'."
                ],
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            if (err.response.status != 401) {
              this.$swal.fire({
                type: "error",
                title: this.$translate.locale["Algo deu errado."],
                text: this.$translate.locale[
                  "Não se preocupe, nossa equipe já está trabalhando para resolver."
                ],
              });
              http.post("message/error", {
                page: "Template Edição",
                message: err.response,
              });
            }
            console.log(err.response);
            this.loading = false;
          });
      } else {
        // template is already saved and user clicked save/share again
        if (isShare) {
          this.loading = true;
          this.imageToShare = this.templateSavedPath;
          this.share();
        } else {
          this.loading = false;
          this.$swal
            .fire({
              type: "success",
              title: this.$translate.locale["Seu template já está salvo."],
            })
            .then(() => {
              // ... on confirm click
            });
        }
      }
    },
    photoCropConfirmed(dataImage) {
      var vm = this; // vue model;
      let objImage = new Image();
      let polygon = vm.clickedGroup.getObjects()[0];
      objImage.src = dataImage;
      vm.clickedGroup.remove(vm.clickedGroup.getObjects()[1]);
      vm.canvas.renderAll();
      objImage.onload = function() {
        let canvasImage = new fabric.Image(objImage, {
          top: polygon.absoluteTop,
          left: polygon.absoluteLeft,
        });
        polygon.set({ fill: "transparent" });
        let circle;
        if (polygon.type == "circle") {
          circle = new fabric.Circle({
            top: -canvasImage.height / 2,
            left: -canvasImage.width / 2,
            radius: canvasImage.width / 2,
            fill: "transparent",
          });
          canvasImage.set({
            clipPath: circle,
          });
        }
        canvasImage.scaleToWidth(polygon.getScaledWidth());
        canvasImage.setCoords();
        vm.clickedGroup.addWithUpdate(canvasImage);
        vm.canvas.renderAll();
      };
      this.templateSavedPath = "";
      this.selectedImage = "";
    },
    photoCropCanceled() {
      this.selectedImage = "";
    },
    processFile() {
      try {
        this.loading = true;
        var vm = this; // vue model;
        var reader = new FileReader();

        if (this.$refs.myFiles.files && this.$refs.myFiles.files[0]) {
          reader.readAsDataURL(this.$refs.myFiles.files[0]);
          reader.onload = function(event) {
            if (event.target.readyState == FileReader.DONE) {
              vm.loading = false;
              vm.selectedImage = event.target.result;
            }
          };
        } else {
          vm.loading = false;
        }
        this.$refs.myFiles.value = null;
      } catch (error) {
        console.log(error);
      }
    },
    loadUserImage(group) {
      group.on("mouseup", () => {
        this.clickedGroup = group;
        let polygon = group.getObjects()[0];
        this.imageCropSettings.aspectRatio =
          polygon.getScaledWidth() / polygon.getScaledHeight();
        this.imageCropSettings.shape = polygon.type;
        let fileInput = document.getElementById("fileInput");
        fileInput.click();
      });
    },
    proportionalSize(originalSize) {
      const adminCanvasSize = 420;
      let percent = (originalSize * 100) / adminCanvasSize;
      return this.canvas.getWidth() * (percent / 100);
    },
    scaleCanvasObjects(obj) {
      obj.left = this.proportionalSize(obj.left);
      obj.top = this.proportionalSize(obj.top);

      if (obj.width) {
        obj.width = this.proportionalSize(obj.width);
      }
      if (obj.height) {
        obj.height = this.proportionalSize(obj.height);
      }
      if (obj.radius) {
        obj.radius = this.proportionalSize(obj.radius);
      }
      if (obj.fontSize) {
        obj.styles = null;
        obj.fontSize = this.proportionalSize(obj.fontSize);
      }
    },
    addCamIcon(obj, index) {
      let groupId = "group" + index;
      fabric.Image.fromURL(require("../assets/images/cam.png"), (img) => {
        img.set({
          left: obj.left + obj.getScaledWidth() / 2 - img.width / 2,
          top: obj.top + obj.getScaledHeight() / 2 - img.height / 2,
        });
        img.selectable = false;

        img.setCoords();

        let group = new fabric.Group([obj, img], {
          id: groupId,
        });
        this.canvas.add(group);
        group.selectable = false;
        this.loadUserImage(group);
      });
    },
    addSignature() {
      var vm = this; // vue model;

      var footer = new fabric.Rect({
        width: vm.canvas.width + 1,
        height: vm.canvas.height * 0.0834,
        top: vm.canvas.height - vm.canvas.height * 0.0834,
        left: -1,
        fill: "#fbfbfb",
        selectable: false,
      });
      vm.canvas.add(footer);

      if (this.user.image) {
        new fabric.Image.fromURL(
          `${this.user.image}?_`,
          (img) => {
            img.set({
              top: vm.canvas.height - vm.canvas.height * 0.28 + 10,
              left: 10,
              selectable: false,
            });
            let circle;
            circle = new fabric.Circle({
              top: -img.height / 2,
              left: -img.width / 2,
              radius: img.width / 2,
              fill: "#fff",
              selectable: false,
            });
            img.set({
              clipPath: circle,
            });
            let circleBorder;
            circleBorder = new fabric.Circle({
              top: img.top - vm.canvas.height * 0.007,
              left: img.left - vm.canvas.width * 0.007,
              radius: img.width / 2,
              fill: "#fff",
              shadow: "4px 4px 12px rgba(0,0,0,0.3)",
              selectable: false,
            });

            circleBorder.scaleToWidth(vm.canvas.width * 0.282);
            vm.canvas.add(circleBorder);

            img.scaleToWidth(vm.canvas.width * 0.27);
            vm.canvas.add(img);

            let trimmedUserName = authentication
              .getUser()
              .info.professionalName.substring(0, 26);

            var name = new fabric.Text(trimmedUserName, {
              fontFamily: "fontBold",
              fontSize: vm.canvas.width * 0.035,
              fontStyle: "italic",
              color: "#000",
              top: vm.canvas.height - footer.height + 1.8,
              left: img.getScaledWidth() + 16,
              selectable: false,
            });
            vm.canvas.add(name);

            let sex = authentication.getUser().info.genre;
            var careerLevel = new fabric.Text(
              authentication.getUser().info.career[sex],
              {
                fontFamily: "fontRegular",
                fontSize: vm.canvas.width * 0.021,
                fontStyle: "italic",
                color: "#000",
                top: vm.canvas.height - footer.height + name.height + 1,
                left: img.getScaledWidth() + 16,
                selectable: false,
              }
            );
            vm.canvas.add(careerLevel);
          },
          { crossOrigin: "anonymous" }
        );
      }

      new fabric.Image.fromURL(
        require("../assets/images/doit-watermark.jpg"),
        (img) => {
          img.scaleToWidth(vm.canvas.width * 0.1645);
          img.set({
            top:
              vm.canvas.height - footer.height / 2 - img.getScaledHeight() / 2,
            left: vm.canvas.width - img.getScaledWidth() - 8,
            selectable: false,
          });
          vm.canvas.add(img);
          vm.canvas.renderAll();
          setTimeout(() => {
            vm.loading = false;
          }, 500);
        },
        { crossOrigin: "anonymous" }
      );
    },
    cancelUserText() {
      this.showTextInput = false;
      this.userMessage = "";
    },
    setUserText() {
      if (this.selectedText && this.userMessage) {
        this.selectedText.text = this.userMessage;
        this.selectedText.set({ myText: this.userMessage });
        this.canvas.renderAll();
        this.userMessage = "";
        this.selectedText = "";
        this.templateSavedPath = "";
      }
      this.showTextInput = false;
    },
    loadCanvas() {
      this.canvas = {};
      const canvasHolder = document.getElementById("canvasHolder");
      this.canvas = new fabric.Canvas("templateCanvas");

      fabric.Object.NUM_FRACTION_DIGITS = 17;
      if (canvasHolder.clientWidth < 1000) {
        this.canvas.setWidth(canvasHolder.clientWidth);
        this.canvas.setHeight(canvasHolder.clientHeight);
      } else {
        this.canvas.setWidth(1000);
        this.canvas.setHeight(1000);
      }

      localStorage.a = this.template.canvas;

      var canvasJson = JSON.parse(this.template.canvas);
      this.canvas.loadFromJSON(canvasJson, () => {
        fabric.util.clearFabricFontCache();
        this.canvas.backgroundImage.src = canvasJson.backgroundImage.src;

        fabric.Image.fromURL(
          `${this.canvas.backgroundImage.src}?_`,
          (img) => {
            img.scaleToHeight(this.canvas.height);
            this.canvas.setBackgroundImage(img);
            this.canvas.renderAll();
            for (let i = 0; i < this.canvas.getObjects().length; i++) {
              let obj = this.canvas.getObjects()[i];
              obj.hasControls = false;
              obj.selectable = false;
              this.scaleCanvasObjects(obj);

              obj.setCoords();

              if (obj.type == "rect" || obj.type == "circle") {
                obj.absoluteTop = obj.top;
                obj.absoluteLeft = obj.left;
                this.addCamIcon(obj, i);
              } else if (obj.type == "textbox") {
                obj.set({ editable: false });
              }
            }

            this.canvas.set({ selection: false });

            this.canvas.on("mouse:over", (event) => {
              if (event.target)
                event.target.hoverCursor = this.canvas.defaultCursor;
            });

            this.canvas.on("mouse:up", (event) => {
              if (
                event &&
                event.target &&
                event.target.type &&
                event.target.type == "textbox"
              ) {
                this.showTextInput = true;
                setTimeout(() => {
                  this.$refs.userTextarea.focus();
                }, 200);
                this.selectedText = event.target;
                if (this.selectedText.myText) {
                  this.userMessage = this.selectedText.myText;
                }
                this.userPlaceholder = this.selectedText.text;
              }
            });

            this.addSignature();
          },
          { crossOrigin: "anonymous" }
        );
      });
    },
    goBack() {},
  },
};
</script>
<style scoped>
.bg-cover.flex-center {
  z-index: 1050;
}

.fontpreload p {
  position: absolute;
  left: -4000px;
  top: -4000px;
  visibility: hidden;
  opacity: 0;
}
.fontpreload p:nth-child(1) {
  font-family: "fontRegular";
}
.fontpreload p:nth-child(2) {
  font-family: "fontBold";
}
.fontpreload p:nth-child(3) {
  font-family: "fontLight";
}
.fontpreload p:nth-child(4) {
  font-family: "AlexBrush";
}
.fontpreload p:nth-child(5) {
  font-family: "BacktoBlack";
}
.fontpreload p:nth-child(6) {
  font-family: "Chapaza";
}
.fontpreload p:nth-child(7) {
  font-family: "FranklinGothic";
}
.fontpreload p:nth-child(8) {
  font-family: "GlossAndBloom";
}
.fontpreload p:nth-child(9) {
  font-family: "MontserratExtraBold";
}
.fontpreload p:nth-child(10) {
  font-family: "MontserratExtraLight";
}
.fontpreload p:nth-child(11) {
  font-family: "MontserratMedium";
}
.fontpreload p:nth-child(12) {
  font-family: "OpenSansBold";
}
.fontpreload p:nth-child(13) {
  font-family: "StackyardPersonal";
}

button.eraser {
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

button.eraser img {
  position: relative;
  max-width: 32px;
  width: initial;
  display: block;
  margin-right: 18px;
}

button.options {
  width: 48%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

button.options img {
  position: relative;
  max-width: 22px;
  max-height: 28px;
  width: initial;
  display: block;
  margin-right: 14px;
}
</style>
