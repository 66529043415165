<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <div class="fontpreload">
      <p>1</p>
      <p>2</p>
      <p>3</p>
      <p>4</p>
      <p>5</p>
      <p>6</p>
      <p>7</p>
      <p>8</p>
      <p>9</p>
      <p>10</p>
      <p>11</p>
      <p>12</p>
      <p>13</p>
      <p>14</p>
      <p>15</p>
      <p>16</p>
      <p>17</p>
      <p>18</p>
    </div>
  </div>
</template>

<script>
import languages from "@/util/languages";
export default {
  name: "App",
  locales: languages,
  created() {
    if (!localStorage.language) {
      this.$router.push("/login");
    }
  },
};
</script>

<style lang="scss">
html,
body,
header,
section,
nav,
canvas,
video,
div,
span,
ul,
li,
a,
article,
h1,
p {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 12px;
  font-family: fontRegular;
  color: #333;
  box-sizing: border-box;
}

:root {
  --brown: #925030;
  --blue: #0f2651;
  --red: #e82121;
  --brown-light: #ffaf88;
}

.text-brown {
  color: var(--brown) !important;
}
.text-blue {
  color: var(--blue) !important;
}
.text-white {
  color: #fff !important;
}
.text-red {
  color: var(--red) !important;
}
.text-brown-light {
  color: var(--brown-light);
}

.bg-blue {
  background-color: var(--blue) !important;
}
.bg-brown {
  background-color: var(--brown) !important;
}
.bg-white {
  background-color: #fff;
}
.bg-red {
  background-color: var(--red) !important;
}

.fontpreload p {
  position: absolute;
  left: -4000px;
  top: -4000px;
  visibility: hidden;
  opacity: 0;
}
.fontpreload p:nth-child(1) {
  font-family: "fontRegular";
}
.fontpreload p:nth-child(2) {
  font-family: "fontBold";
}
.fontpreload p:nth-child(3) {
  font-family: "fontLight";
}
.fontpreload p:nth-child(4) {
  font-family: "AlexBrush";
}
.fontpreload p:nth-child(5) {
  font-family: "BacktoBlack";
}
.fontpreload p:nth-child(6) {
  font-family: "Chapaza";
}
.fontpreload p:nth-child(7) {
  font-family: "FranklinGothic";
}
.fontpreload p:nth-child(8) {
  font-family: "GlossAndBloom";
}
.fontpreload p:nth-child(9) {
  font-family: "MontserratExtraBold";
}
.fontpreload p:nth-child(10) {
  font-family: "MontserratExtraLight";
}
.fontpreload p:nth-child(11) {
  font-family: "MontserratMedium";
}
.fontpreload p:nth-child(12) {
  font-family: "OpenSansBold";
}
.fontpreload p:nth-child(13) {
  font-family: "StackyardPersonal";
}
.fontpreload p:nth-child(14) {
  font-family: "Freeland";
}
.fontpreload p:nth-child(15) {
  font-family: "Signatie";
}
.fontpreload p:nth-child(16) {
  font-family: "AnastaciaOne";
}
.fontpreload p:nth-child(17) {
  font-family: "Frintake";
}
.fontpreload p:nth-child(18) {
  font-family: "MaryKayScriptBold";
}
@font-face {
  font-family: "fontRegular";
  src: url("./assets/fonts/ProximaNova-Regular.ttf");
}

@font-face {
  font-family: "fontBold";
  src: url("./assets/fonts/ProximaNova-Bold.ttf");
}

@font-face {
  font-family: "fontLight";
  src: url("./assets/fonts/ProximaNova-Light.ttf");
}

@font-face {
  font-family: "AlexBrush";
  src: url("./assets/fonts/AlexBrush-Regular.ttf");
}
@font-face {
  font-family: "BacktoBlack";
  src: url("./assets/fonts/BacktoBlack.ttf");
}
@font-face {
  font-family: "Chapaza";
  src: url("./assets/fonts/Chapaza.ttf");
}
@font-face {
  font-family: "FranklinGothic";
  src: url("./assets/fonts/franklin-gothic-demi.ttf");
}
@font-face {
  font-family: "GlossAndBloom";
  src: url("./assets/fonts/Gloss_And_Bloom.ttf");
}
@font-face {
  font-family: "MontserratExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "MontserratExtraLight";
  src: url("./assets/fonts/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: "MontserratMedium";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "OpenSansBold";
  src: url("./assets/fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "StackyardPersonal";
  src: url("./assets/fonts/Stackyard_PERSONAL.ttf");
}

@font-face {
  font-family: "Freeland";
  src: url("./assets/fonts/Freeland.ttf");
}
@font-face {
  font-family: "Signatie";
  src: url("./assets/fonts/Signatie.otf");
}
@font-face {
  font-family: "AnastaciaOne";
  src: url("./assets/fonts/ANASTACIA-ONE.otf");
}
@font-face {
  font-family: "Frintake";
  src: url("./assets/fonts/FONTSFREE-NET-FRINTAKE.ttf");
}
@font-face {
  font-family: "MaryKayScriptBold";
  src: url("./assets/fonts/MaryKayScript-Bold.ttf");
}

.shell {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.swal2-content * {
  font-size: 20px;
}

.cards-home-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.card-title {
  position: relative;
  font-family: fontRegular;
  margin: 10px 0 0 0px;
  font-size: 1.25em;
  line-height: 1.15em;
  z-index: 2;
}

.cards-home-holder.small-children {
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}

@media only screen and (min-width: 1140px) {
  .cards-home-holder {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
  }

  .cards-home-holder.small-children {
    grid-template-columns: repeat(auto-fit, minmax(140px, 0.2fr));
  }

  .card-title {
    font-size: 1.4em;
  }
}

@media only screen and (min-width: 721px) and (max-width: 1139px) {
  .cards-home-holder {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.4rem;
  }
}

.no-zoom:hover {
  transform: scale(1) !important;
}

.loading-spinner {
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading-spinner.blue {
  border: 2px solid #0f2651;
  border-right-color: transparent;
}

.bg-cover {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 9999;
}

@-webkit-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-anim1 {
  animation-name: anim1;
  animation-duration: 0.3s;
}

.page-anim2 {
  animation-name: anim2;
  animation-duration: 0.3s;
}

.page-anim3 {
  animation-name: anim3;
  animation-duration: 0.3s;
}

@keyframes anim1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes anim2 {
  from {
    opacity: 0;
    transform: translateX(17vw);
  }
  to {
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes anim3 {
  from {
    opacity: 0;
    transform: translateY(25vw);
  }
  to {
    opacity: 1;
    transform: translateY(0vw);
  }
}
</style>
