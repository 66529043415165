<template>
	<div>
		<Header :showBackButton="true" />

		<section class="page-content page-anim2">
			<div class="container pb-2">
				<div class="flex-between-desktop">
					<div>
						<h1 class="title text-blue my-1 section-title">
							<span>{{ t("Categoria") }}: </span> {{ category.title }}
						</h1>
						<p class="text-desc text-brown text-bold desktop11 mb-1-desktop">
							{{ category.description }}
						</p>
					</div>
					<div
						v-if="subcategories.length > 0"
						class="input-holder labeled mt-2 desktop6 tablet12 mobile12"
					>
						<p class="text-desc text-brown text-bold">
							{{ t("Subcategorias") }}
						</p>
						<img src="../assets/images/price-tag.png" />
						<select @change="filterTemplates()" v-model="subCatId">
							<option value>{{ t("Todas") }}</option>
							<option
								v-for="subCat in subcategories"
								:key="subCat.id"
								:value="subCat.id"
								>{{ subCat.title }}</option
							>
						</select>
					</div>
				</div>

				<div class="cards-home-holder small-children mt-2">
					<div
						v-show="!error"
						v-for="template in templates"
						:key="template.id"
						@click="$router.push(`/template/${template.id}`)"
						class="category-card"
					>
						<div class="img-holder">
							<img class="bg-img" :src="template.coverImage" />
						</div>
						<p class="text-desc card-desc text-brown">{{ template.title }}</p>
					</div>
				</div>

				<div v-show="loading" class="flex-center mt-2">
					<div class="loading-spinner blue"></div>
				</div>

				<div
					v-show="templates.length == 0 && !loading && !error"
					class="flex-center mt-2"
				>
					<p class="text-desc text-center mt-3">
						{{ t("Nenhum template encontrado.") }}
					</p>
				</div>

				<div class="container pb-2 template-edit-holder">
					<div v-show="error && !loading">
						<p class="text-desc mt-2 text-center">
							{{
								t(
									"Você não possui um plano ativo. Para verificar os detalhes do seu plano, acesse:"
								)
							}}
						</p>
						<a href="#/meusDados?tab=plan">
							<button class="default options bg-blue mt-1">
								<span>{{ t("Meu plano") }}</span>
							</button>
						</a>
					</div>

					<div v-show="error && !loading">
						<p class="text-desc mt-3 text-center">
							{{ t("Para obter downloads agora mesmo:") }}
						</p>
						<a href="#/pacotes">
							<button class="default options bg-blue mt-1">
								<span>{{ t("Obter downloads") }}</span>
							</button>
						</a>
					</div>
				</div>

				<button
					v-show="!allTemplatesFound && !loading && templates.length != 0"
					@click="loadMoreTemplates()"
					class="default"
				>
					<span>{{ t("VER MAIS") }}</span>
				</button>
			</div>
		</section>
	</div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import authentication from "../util/authentication";
export default {
  name: "TemplateLista",
  components: {
    Header,
  },
  data() {
    return {
      error: false,
      loading: true,
      subcategories: [],
      category: {
        title: "",
      },
      subCatId: "",
      templates: [],
      page: 1,
      take: 15,
      allTemplatesFound: false,
    };
  },
  created() {
    http
      .get(`category/${this.$route.params.id}`, authentication.getHeaders())
      .then((response) => {
        this.category = response.data;
        this.loadSubcategories();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    loadMoreTemplates() {
      this.loading = true;
      this.page = Number(this.page) + 1;
      this.loadTemplates();
    },
    filterTemplates() {
      this.templates = [];
      this.page = 1;
      this.loadTemplates();
    },
    loadSubcategories() {
      if (this.category.id) {
        http
          .get(
            `subcategory?category=${this.category.id}`,
            authentication.getHeaders()
          )
          .then((response) => {
            this.subcategories = response.data.list;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loadTemplates();
          });
      }
    },
    loadTemplates() {
      this.loading = true;
      http
        .get(
          `template?page=${this.page}&take=${this.take}&category=${this.category.id}&subcategory=${this.subCatId}`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.data.error) {
            this.error = true;
          } else {
            this.error = false;
            this.templates = [...this.templates, ...response.data.list];
            if (response.data.list.length < this.take) {
              this.allTemplatesFound = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {},
  },
};
</script>
<style scoped>
	button.default {
		max-width: 340px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.title span {
		position: relative;
		font-family: fontLight;
		font-size: 0.5em;
		color: #ba8368;
		margin-right: 10px;
		top: -5px;
	}
</style>
